import React from "react";
import styled from "../../../theming/styled";

import { defaultTheme } from "./../../../theming/theme";
const { fonts } = defaultTheme; // cause not in context

const BubbleHotspot = ({ quote, person, translation, direction}) => {


  return (
    <BubbleHotspotHolder className={`active ${direction}`} style={{ transform: `translate(${translation.x}px, ${translation.y}px)` }}>
        <div className="bubbleContent">
          <div className="quote" dangerouslySetInnerHTML={{ __html: quote }}>       
          </div>
          <div className="person" dangerouslySetInnerHTML={{ __html: person }}>
          </div>
      </div> 
    </BubbleHotspotHolder>
  );
};

export default BubbleHotspot;

const BubbleHotspotHolder = styled.div(() => ({
  pointerEvents:'none',
  width: "830px",
  height: "630px",
  marginTop: "-30vh",
  background: "url(assets/bubbleRight.png) 0 0/contain no-repeat transparent",
  display: "none",
  justifyContent: "middle",
  flexDirection: "column",
  opacity: "0",
  transition: ".5s opacity ease",

  "&.active": {
    display: "flex",
    opacity: "1",
  },
  "&.left": {
    backgroundImage: "url(assets/bubbleLeft.png)",
  },

  ".bubbleContent": {
      padding: "110px 130px 150px 150px",
      fontFamily:fonts.fontFamily,
      color: 'white',

      ".quote": {
        fontSize:'1.5rem',
        fontStyle: 'italic',
      },
      ".person": {
        marginTop: '18px',
        fontSize:'2rem', 
      },
    }, 
}));
