import styled, { CreateStyled } from '@emotion/styled'

import { Theme } from './theme'

// This can be used in ThemedMyComponent for type safety
export default styled as CreateStyled<Theme>

const breakpoints = [600, 1025, 1170, 1264, 1920]
const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)
export { mq }
