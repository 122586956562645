import React, { useRef, useState, useEffect } from "react";
import usePano from "./usePano";
import "./Pano.scss";
import { parseScenes } from "./scenes/parseScenes";
import { parseHotspots } from "./hotspots/parseHotspots";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import { useHologramContext } from "./../../utils/hologramContext";
import {
  TransitionContext,
  useActionContext,
} from "./../../utils";
import { useContextSelector } from "use-context-selector";

import APP_DATA from "./../../data/data";

export interface PanoProps {
  onRoomChange?: Function;
  onActionEvent?: Function;
  match: any;
}

const Pano: React.FC<PanoProps> = (props) => {
  const {
    onRoomChange,
    onActionEvent,
    match: {
      params: { room = "atrium" },
    },
  } = props;

  const [scenesParsed, setScenesParsed] = useState([]);
  const [hotspots, setHotspots] = useState([]);
  const [hologramIsOpen, setHologramIsOpen] = useState("");
  console.log(hologramIsOpen);

  const stage = useRef(null);
  const history = useHistory();

  const dispatchTransition = useContextSelector(
    TransitionContext,
    ({ dispatch }) => dispatch
  );

  /* const dispatchAction = useContextSelector(
    ActionContext,
    ({ dispatch }) => dispatch
  );
 */
  const {
    state: { open: hologramOpen },
    dispatch: hologramDispatch,
  } = useHologramContext();

  const { state: actionState } = useActionContext();

  useEffect(() => {
    const { type, params } = actionState;
    switch (type) {
      case "showHologram":
        return hologramDispatch({
          type: hologramOpen === "" ? "OPEN_HOLOGRAM" : "CLOSE_HOLOGRAM",
          id: params.id,
        });
      case "closeHologram":
        return hologramDispatch({ type: "CLOSE_HOLOGRAM" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionState]);

  const changeScene = (newScene) => {
    history.push(newScene);
  };

  const handleClickOutside = (event) => {
    const holograms = document.getElementsByClassName("hologram");
    let activeHologram = null;
    Array.from(holograms).forEach((hologram) => {
      if (hologram.getAttribute("data-id") === hologramOpen) {
        activeHologram = hologram;
      }
    });

    if (activeHologram !== null && !activeHologram.contains(event.target)) {
      hologramDispatch({ type: "CLOSE_HOLOGRAM" });
      crappySetHologramIsOpen();
    }
  };

  const crappySetHologramIsOpen = () => {
    const holograms = document.getElementsByClassName("hologram");
    Array.from(holograms).forEach((hologram) => {
      hologram.setAttribute(
        "style",
        `display:${
          hologram.getAttribute("data-id") === hologramOpen ? "block" : "none"
        }`
      );
    });
    setHologramIsOpen(hologramOpen);
  };

  useEffect(() => {
    onRoomChange(room);
    pauseAllVideos();
    setScenesParsed(
      scenesParsed.map((scene) => ({
        ...scene,
        current: scene.id === room,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  useEffect(() => {
    const __scenesParsed = parseScenes(APP_DATA.scenes);
    setScenesParsed(
      __scenesParsed.map((scene) => ({
        ...scene,
        current: scene.id === room,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [, currentScene] = usePano(stage, {
    hotspots,
    onLoad: () => {},
    scenes: scenesParsed,
  });

  const pauseAllVideos = () => {
    const videos = document.getElementsByTagName("video");
    Array.from(videos).forEach((video) => {
      try {
        video.pause();
      } catch (error) {}
    });
  };

  useEffect(() => {
    const __hotspotsParsed = parseHotspots(
      scenesParsed,
      changeScene,
      onActionEvent
    );
    setHotspots(__hotspotsParsed);
    if (currentScene)
      currentScene.view().addEventListener("change", () => {
        const cs = currentScene.view();
        dispatchTransition({
          type: "UPDATE_TRANSITION",
          state: { pitch: cs.pitch(), yaw: cs.yaw(), fov: cs.fov() },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScene, hologramOpen]);

  useEffect(() => {
    crappySetHologramIsOpen();
    if (hologramOpen) {
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hologramOpen]);

  return <div ref={stage} className="pano-container"></div>;
};
export default withRouter(Pano);
