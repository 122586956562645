import React from "react";
import styled from "../../../theming/styled";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";

const VideoHotspot = ({ source, translation }) => {
  return (
    <div
      style={{ transform: `translate(${translation.x}px, ${translation.y}px)` }}
    >
      <VideoHotspotHolder>
     
        <Plyr
          source={{
            type: "video",
            sources: [
              {
                src: source,
              },
            ],
          }}
        />
      </VideoHotspotHolder>
    </div>
  );
};
export default VideoHotspot;

const VideoHotspotHolder = styled.div(() => ({
  backgroundColor: "#444",
  position: "relative",
  width: "1280px",
  height: "720px",
  marginTop: "-255px",
}));
