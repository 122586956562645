const BIOGAS = [
  
  {
    "id": "biogas-anlage",
    "parent": "atrium",
    hasChildren: true,
    hideFromMenu: true,
    hasMenu: false,
    "name": "Laboratory Biogas Plant",
    /*initialPitchLimiter: {
      min: 0,
      max: 0,
    },*/
    initialYawLimiter: {
      min: 1.3,
      max: 2.2,
    },
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.9705016962699284,
      "pitch": 0.06806256026999691,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
     /* {
        "yaw": 0.32,
        "pitch": 0.14203559349486383,
        "rotation": 0,
        "target": "atrium",
        isRoom: true
      }*/
    ],
    "infoHotspots": [
      {
        yaw: 1.5,
        pitch: 0,
        type: "small",
        translateX: "-700px",
        translateY: "200px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Transport container (liquid)",
        showInNavbar: true,
       },{
        yaw: 1.4,
        pitch: 0,
        type: "small",
        translateX: "-300px",
        translateY: "-500px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Wassertank",
        showInNavbar: true,
       },{
        yaw: 1.6,
        pitch: 0,
        type: "small",
        translateX: "-400px",
        translateY: "-30px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Gas analysis",
        showInNavbar: true,
       },
       {
        yaw: 1.6,
        pitch: 0,
        type: "small",
        translateX: "-460px",
        translateY: "240px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Fermentation product container",
        showInNavbar: true,
       },
       {
        yaw: 1.8,
        pitch: 0,
        type: "small",
        translateX: "-440px",
        translateY: "-180px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Substrate valves",
        showInNavbar: true,
       },
       {
        yaw: 1.8,
        pitch: 0,
        type: "small",
        translateX: "-360px",
        translateY: "0",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Reactor 2",
        showInNavbar: true,
       },
       {
        yaw: 1.9,
        pitch: 0,
        type: "small",
        translateX: "-270px",
        translateY: "-240px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Gas meter",
        showInNavbar: true,
       },
       {
        yaw: 2,
        pitch: 0,
        type: "small",
        translateX: "-100px",
        translateY: "180px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Reactor 1",
        showInNavbar: true,
       },
       {
        yaw: 2,
        pitch: 0,
        type: "small",
        translateX: "-100px",
        translateY: "380px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Reactor heater",
        showInNavbar: true,
       },
       {
        yaw: 2,
        pitch: 0,
        type: "small",
        translateX: "0px",
        translateY: "630px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Measuring sensor (PH / Redox)",
        showInNavbar: true,
       },
       {
        yaw: 2.1,
        pitch: 0,
        type: "small",
        translateX: "350px",
        translateY: "330px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Substrate pump",
        showInNavbar: true,
       },
       {
        yaw: 2.1,
        pitch: 0,
        type: "small",
        translateX: "100px",
        translateY: "0px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Frequency converter",
        showInNavbar: true,
       },
       {
        yaw: 2.2,
        pitch: 0,
        type: "small",
        translateX: "300px",
        translateY: "-300px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Solid feeding",
        showInNavbar: true,
       },
       {
        yaw: 2.3,
        pitch: 0,
        type: "small",
        translateX: "400px",
        translateY: "300px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Storage container (liquid)",
        showInNavbar: true,
       },
       {
        yaw: 2.3,
        pitch: 0,
        type: "small",
        translateX: "600px",
        translateY: "200px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Storage container (solid - liquid)",
        showInNavbar: true,
       },       
       {
        yaw: 1,
        pitch: 0,
        translateX: "-360px",
        translateY: "-170px",
        radius: 550,
        action: "showImageBox",
        thumb: "/material/biogasanlage/umgebung.jpg",
        params: {
          slides: [
            "/material/biogasanlage/umgebung.jpg",
          ],
          pdf: "/material/biogasanlage/Info_Biogas.pdf",
        },
        title: "Laboratory Biogas Plant",
        showInNavbar: true,
       }
    ]
  },
];
export default BIOGAS;
