import React from "react";
import styled from "../../../theming/styled";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

const SideshowHotspot = ({ slides, translation }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove:false
  };

  return (
    <SideshowHotspotHolder>
      <div style={{ transform: `translate(${translation.x}px, ${translation.y}px)` }}>
        <Slider {...settings}>
          {slides.map((slide, index) => {
            return (
              <div key="$(index)" className="slide">
                <div
                  className="slideImage"
                  style={{ backgroundImage: `url(${slide})` }}
                ></div>
              </div>
            );
          })}
        </Slider>
      </div>
    </SideshowHotspotHolder>
  );
};
export default SideshowHotspot;

const SideshowHotspotHolder = styled.div(() => ({
  position: "relative",
  width: "1280px",
  height: "730px",
  marginTop: "90px",

  ".slide": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "730px",
    fontSize: "10rem",
    textAlign: "center",

    ".slideImage": {
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
  },
  ".slick-arrow": {
    width: "120px",
    height: "120px",
    fontSize: 0,
    padding: 0,
    border: "none",
    outline: "none",
    background: "transparent",
    color: "#fff",
    cursor: "pointer",
    position: "absolute",
    top: "300px",
    "&.slick-prev": {
      left: "-12rem",
      transform: "rotate(180deg)",
    },
    "&.slick-next": {
      right: "-12rem",
    },
    "&:after": {
      content: "'>'",
      fontSize: "6rem",
    },
  },
}));
