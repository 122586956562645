import React from "react";

export const defaultState = {
  type: "",
  params:null
};

export type State = typeof defaultState;

export interface Action {
  params?: any;
  type: string;
}

export const reducer: React.Reducer<State, Action> = (state, action) => {
  return { ...state, type:action.type, params: action.params };
};

export const ActionContext = React.createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({ state: defaultState, dispatch: () => null });

export const ActionContextProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  return (
    <ActionContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ActionContext.Provider>
  );
};

export const useActionContext = () => React.useContext(ActionContext);
