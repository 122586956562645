import React from "react";

export const defaultState = {
  open: "",
};

export type State = typeof defaultState;

export interface Action {
  type: "OPEN_HOLOGRAM" | "CLOSE_HOLOGRAM";
  id?: string;

}

export const reducer: React.Reducer<State, Action> = (state, action) => {
  console.log("state", state);
  console.log("action", action);
  
  switch (action.type) {
    case "OPEN_HOLOGRAM":
      return { ...state, open: action.id };
    case "CLOSE_HOLOGRAM":
      return { ...state, open: "" };
    default:
      return state;
  }
};

export const HologramContext = React.createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({ state: defaultState, dispatch: () => null });

export const HologramContextProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  return (
    <HologramContext.Provider value={{ state, dispatch }}>
      {props.children}
    </HologramContext.Provider>
  );
};

export const useHologramContext = () => React.useContext(HologramContext);
