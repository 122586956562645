import React from "react";
import styled from "../../../theming/styled";

import { defaultTheme } from "./../../../theming/theme";
const { fonts } = defaultTheme; // cause not in context

const HotspotText = ({ children }) => {
  return (
    <HotspotTextHolder>
     {children}
    </HotspotTextHolder>
  );
};
export default HotspotText;

const HotspotTextHolder = styled.div(() => ({
  fontFamily:fonts.fontFamily,
  fontSize:'2rem',
  whiteSpace:'nowrap',
  color: 'white',
  padding:'0 11rem 20rem',
  marginTop:'20rem',
  /*backgroundColor:'#404049',*/
  cursor:'pointer',
  '&:hover':{
    textShadow: '#FFF 0px 0px 2px, #FFF 0px 0px 5px, #FFF 0px 0px 8px'
  }
}));

