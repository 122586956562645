import useViewer from "./useViewer";
import { useScenes } from "./scenes";
import { useHotspots } from "./hotspots";

const usePano = (viewerCanvas, props) => {
  const viewer = useViewer(viewerCanvas);

  const { scenes: sceneSpecs, hotspots: hotspotSpecs, onLoad } = props;

  const [, currentScene] = useScenes(viewer, sceneSpecs, onLoad);

  // Hotspot Loading
  const hotspotContainer =
    currentScene && currentScene.hotspotContainer
      ? currentScene.hotspotContainer()
      : null;

  const hotspots = useHotspots(hotspotContainer, hotspotSpecs);
  
  return [viewer, currentScene, hotspots];
};
export default usePano;


