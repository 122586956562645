import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Submenu from "./Submenu";
import { useActionContext } from "./../../utils";
import APP_DATA from "../../data/data";

const NavOptions = (props) => {
  const { handleToggle, history } = props;
  const [showSubmenu, setShowSubmenu] = useState(false);
  const { dispatch: dispatchAction } = useActionContext();
  const navOptions = props.navOptions;
  const position = props.position;
  const submenuOptions = props.submenuOptions;
  const closeAllVideos = () => {
    const videos = document.getElementsByTagName("video");
    Array.from(videos).forEach((video) => {
      try {
        video.pause();
      } catch (error) {}
    });
    const videoHolder = document.getElementById("imagebox-container");
    if (videoHolder) videoHolder.remove()
  };
  const handleClick = ({ action, sceneId, image, params }) => {
    handleToggle && handleToggle();
    switch (action) {
      case "sceneLink":
        closeAllVideos();
        return history.push(`${sceneId}`);
      case "openLink": 
        closeAllVideos();
        return (window.location.href = `${sceneId}`);
      case "goBack":
        closeAllVideos();
        // SEARCH PARENT        
        const currentRoom = APP_DATA.scenes.find(
          (scene) => scene.id === history.location.pathname.substring(1)
        );
        return (currentRoom && currentRoom["parent"])
          ? history.push(`${currentRoom["parent"]}`)
          : history.push(``);

      case "share":
        return (window.location.href = params.mailto);
      case "openSubmenu":
        return setShowSubmenu(!showSubmenu);
      default:

        closeAllVideos();
        dispatchAction({
          type: action,
          params,
        });
        return;
    }
  };

  const options = navOptions.map(
    (
      { label, action, icon = "", params = {}, sceneId = "", image = "" },
      key
    ) => (
      <div
        className={"nav-option"} // + (activeItem === action ? " clicked" : "")
        onClick={(e) => handleClick({ action, params, sceneId, image })}
        key={key}
        data-nav={action}
        data-icon={icon}
      >
        {showSubmenu && sceneId === "submenu" && submenuOptions && (
          <Submenu navOptions={submenuOptions} />
        )}

        {label}
      </div>
    )
  );

  return <div className={position}>{options}</div>;
};

export default withRouter(NavOptions);
