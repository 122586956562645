const ATRIUM = [  
  {
    "id": "atrium",
    "name": "Empfangshalle",
    "hasChildren": true,
    "hasMenu": true,
    "parent": "",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": -1.4029295326341007,
      "pitch": 0.13064140715332329,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": -1.3515857476355428,
        "pitch": 0.146531757683789,
        "rotation": 0,
        "target": "empfang"
      },
      {
        "yaw": -2.0442627780006113,
        "pitch": 0.1557774391490021,
        "rotation": 0,
        "target": "ines"
      },
      {
        "yaw": -2.9083927194272885,
        "pitch": 0.13748275281141886,
        "rotation": 0,
        "target": "study",
        isRoom: true
      },
      {
        "yaw": 2.934138328400034,
        "pitch": 0.14665948470508638,
        "rotation": 0,
        "target": "bayfor",
        isRoom: true
      },
      {
        "yaw": 2.405262910367764,
        "pitch": 0.08644133602578563,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      },
      {
        "yaw": 2.1620983128365614,
        "pitch": 0.07663922093641062,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      },
     /* {
        "yaw": -2.5203274769452566,
        "pitch": 0.0356635083336343,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.5203274769452566,
        "pitch": -0.0356635083336343,
        "rotation": 0,
        "target": "0-lobby"
      }, 
      {
        "yaw": -0.7222341306602011,
        "pitch": 0.040643885024724824,
        "rotation": 0,
        "target": "waermetechnischer-pruefstand"
      },
      {
        "yaw": 2.6860265797150067,
        "pitch": -0.10122326051267905,
        "rotation": 0,
        "target": "biogas-anlage"
      },*/
      {
        "yaw": -0.05675374787907117,
        "pitch": 0.13722695731017254,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      },
      {
        "yaw": 0.292709140104467,
        "pitch": 0.12003345997263537,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      },
      {
        "yaw": 0.6136716565921976,
        "pitch": 0.11642147239405531,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      },
      {
        "yaw": 0.953869413009631,
        "pitch": 0.11788647255533213,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": 1.241678152213849,
        "pitch": 0.10077902430235142,
        "rotation": 0,
        "target": "mount-kenya",
        isRoom: true
      },
      {
        "yaw": 1.4735969968300848,
        "pitch": 0.02407671504251368,
        "rotation": 0,
        "target": "mombasa",
        isRoom: true
      },
      {
        "yaw": 1.693234307032558,
        "pitch": 0.07747559112834445,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true

      },
      {
        "yaw": 1.927114277373378,
        "pitch": 0.07225963501495158,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      }
    ],/*
    "embeddedHotspots": [
      {
        type: "video", 
        source: "/material/rollups/video_with_thumb.mp4",
        yaw: -1,
        pitch: 0,
        radius: 1500,
        translateX: -430,
        translateY: -600,
      }
    ],*/
    "infoHotspots": [
     /* {
        yaw: 5.1,
        pitch: -0.1,
        translateX: "-500px",
        translateY: "220px",
        radius: 1050,
        action: "showImageBox",
        type: "bobbel",
        params: {
          slides: [
            "/material/rollups/Biogas40.png"
          ],
          pdf: "https://www.biogas4null.de/ ",
          pdftitle: "Zur Website"
        },
        title: "Biogas 4.0 Roll-Up",
        showInNavbar: true,
      }, */
      {
        yaw: 5.68,
        pitch: -0.06,
        translateX: "-500px",
        translateY: "220px",
        radius: 1050,
        action: "showImageBox",
        type: "bobbel",
        params: {
          slides: [
            "/material/rollups/InES.png"
          ],
          pdf: "https://www.thi.de/go/energy",
          pdftitle: "Website"
        },
        title: "InES Roll-Up",
        showInNavbar: true,
      },
      {
        yaw: 4.45,
        pitch: -0.1,
        translateX: "-500px",
        translateY: "220px",
        radius: 1050,
        action: "showImageBox",
        type: "bobbel",
        params: {
          slides: [
            "/material/ines/afrika/x_Seite_1.jpg"
          ],
          pdf: "/material/ines/afrika/rollup.pdf"
        },
        title: "Academic Research Partners in Africa",
        showInNavbar: true,
      },
     /* {
        yaw: 6.25,
        pitch: -0.1,
        translateX: "-500px",
        translateY: "220px",
        radius: 1050,
        action: "showImageBox",
        type: "bobbel",
        params: {
          slides: [
            "/material/rollups/WaermeWohnen.png"
          ],
          pdf: "https://www.waerme-wohnen.org/",
          pdftitle: "Zur Website"
        },
        title: "Wärme & Wohnen Roll-Up",
        showInNavbar: true,
      }*/
    ]
  },
  {
    "id": "empfang",
    "name": "Reception",
    "parent": "atrium",
    hasChildren: true,
    hasMenu: true,
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 0.702339381630854,
      "pitch": 0.2950446120155827,
      "fov": 2
    },
    "linkHotspots": [
      {
        "yaw": -1.325152002474617,
        "pitch": 0.19946488217736302,
        "rotation": 0,
        "target": "ines"
      },
     /* {
        "yaw": -1.230436495526586,
        "pitch": -0.08682778445878995,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -1.230436495526586,
        "pitch": -0.01682778445878995,
        "rotation": 0,
        "target": "0-lobby"
      }, */
      {
        "yaw": -2.264650098354327,
        "pitch": -0.024555860653285393,
        "rotation": 0,
        "target": "bayfor",
        isRoom: true
      },
      {
        "yaw": -2.0809538050075214,
        "pitch": -0.02001548146994956312,
        "rotation": 0,
        "target": "study",
        isRoom: true
      },
      {
        "yaw": -2.408668849402183,
        "pitch": -0.000373475762434978,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      },
      {
        "yaw": -2.5389279093367387,
        "pitch": -0.0458706399587507,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      },
     /* {
        "yaw": 1.9580526432154253,
        "pitch": -0.009277102080348598,
        "rotation": 0,
        "target": "biogas-anlage"
      }, */
      {
        "yaw": -2.6757150623898664,
        "pitch": -0.02979567715045816,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      },
      {
        "yaw": -2.8216669529475726,
        "pitch": -0.030453135249662637,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true
      },
      {
        "yaw": -2.943640895814197,
        "pitch": -0.005752887682623395,
        "rotation": 0,
        "target": "mombasa",
        isRoom: true
      },
      {
        "yaw": -3.056466463168903,
        "pitch": -0.042163251290923995,
        "rotation": 0,
        "target": "mount-kenya",
        isRoom: true
      },
      {
        "yaw": 3.094213179470437,
        "pitch": -0.00274288201372883,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": 2.925965349691309,
        "pitch": -0.0220397426828537846,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      },
      {
        "yaw": 2.771695649256099,
        "pitch": 0.009894343353906265,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      },
      {
        "yaw": 2.5949106628934953,
        "pitch": -0.0109600852931937,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      }
    ],
    "infoHotspots": [
     {
        yaw: .2,
        pitch: 0,
        translateX: "-500px",
        translateY: "220px",
        radius: 1050,
        action: "showImageBox",
        thumb: "/material/empfang/agenda/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/empfang/agenda/x_Seite_1.jpg",
            "/material/empfang/agenda/x_Seite_2.jpg",
            "/material/empfang/agenda/x_Seite_3.jpg"
          ],
          pdf: "/material/empfang/agenda/agenda.pdf",
        },
        title: "Agenda",
        showInNavbar: true,
      },  /*{
        yaw: 1,
        pitch: -.1,
        radius: 800,
        translateX: "140px",
        translateY: "470px",
        action: "showImageBox",
        thumb: "/material/empfang/biogasnacheeg/x_Seite_01.jpg",
        params: {
          slides: [
            "/material/empfang/biogasnacheeg/x_Seite_01.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_02.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_03.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_04.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_05.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_06.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_07.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_08.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_09.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_10.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_11.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_12.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_13.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_14.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_15.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_16.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_17.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_18.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_19.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_20.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_21.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_22.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_23.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_24.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_25.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_26.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_27.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_28.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_29.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_30.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_31.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_32.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_33.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_34.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_35.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_36.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_37.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_38.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_39.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_40.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_41.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_42.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_43.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_44.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_45.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_46.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_47.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_48.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_49.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_50.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_51.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_52.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_53.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_54.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_55.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_56.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_57.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_58.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_59.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_60.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_61.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_62.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_63.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_64.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_65.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_66.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_67.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_68.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_69.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_70.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_71.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_72.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_73.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_74.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_75.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_76.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_77.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_78.jpg",
          ],
          pdf: "/material/empfang/biogasnacheeg/REzAB_Biogas_nach_dem_EEG_Broschuere.pdf",
        },
        title: "Biogas nach EEG",
        showInNavbar: true,
       }, {
        yaw: 1.2,
        pitch: 0,
        translateX: "400px",
        translateY: "220px",
        radius: 1050,
        action: "showImageBox",
        thumb: "/material/empfang/ww_leitfaden/x_Seite_01.jpg",
        params: {
          slides: [
            "/material/empfang/ww_leitfaden/x_Seite_01.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_02.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_03.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_04.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_05.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_06.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_07.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_08.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_09.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_10.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_11.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_12.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_13.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_14.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_15.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_16.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_17.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_18.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_19.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_20.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_21.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_22.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_23.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_24.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_25.jpg",
          ],
          pdf: "/material/empfang/ww_leitfaden/WaermeWohnen_Leitfaden_zur_Sanierung_von_Waermenetze.pdf",
        },
        title: "W&W Leitfaden (aus dem Vorgängerprojekt Wärme&Wohnen)",
        showInNavbar: true,
      }, */
    
     /* {
        yaw: .6,
        pitch: 0,
        translateX: "-220px",
        translateY: "450px",
        radius: 850,
        action: "showImageBox",
        type: "small",
        thumb: "/material/empfang/natar/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/empfang/natar/x_Seite_1.jpg",
          ],
          pdf: "/material/empfang/natar/NATAR.pdf",
        },
        title: "Poster NATAR – Beispiel Dollnstein",
        showInNavbar: true,
      }, 
      {
        yaw: .7,
        pitch: 0,
        translateX: "-100px",
        translateY: "550px",
        radius: 850,
        action: "showImageBox",
        type: "small",
        thumb: "/material/empfang/flexlac/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/empfang/flexlac/x_Seite_1.jpg"
          ],
          pdf: "/material/flexlac/agenda/flexLAC.pdf",
        },
        title: "Poster FlexLAC",
        showInNavbar: true,
      } */
    ]
  },
  {
    "id": "ines",
    "parent": "atrium",
    "name": " Institut of new Energy Systems (InES)",
    hasChildren: true,
    hasMenu: true,
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": -2.4251668917218517,
      "pitch": 0.30806026144045248,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
    /*  {
        "yaw": -0.05130304079029102,
        "pitch": -0.1551498731198908,
        "rotation": 0,
        "target": "biogas-anlage"
      },
      {
        "yaw": -2.89130304079029102,
        "pitch": -0.0551498731198908,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.89130304079029102,
        "pitch": -0.1251498731198908,
        "rotation": 0,
        "target": "0-lobby"
      }, */
      { 
        "yaw": -0.2947320469438992,
        "pitch": 0.1517160703649072,
        "rotation": 0,
        "target": "empfang"
      },
      {
        "yaw": 1.9213095293887504,
        "pitch": 0.024875602176709606,
        "rotation": 0,
        "target": "study",
        isRoom: true
      },
      {
        "yaw": 0.470507846121133,
        "pitch": 0.008820927900808684,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      },
      {
        "yaw": 0.640034759601803,
        "pitch": 0.006875057166793042,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      },
      {
        "yaw": 0.8123830065730533,
        "pitch": 0.001190364133526032,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      },
      {
        "yaw": 0.9780553512614723,
        "pitch": -0.032958545674248256,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": 1.1284470108297646,
        "pitch": 0.004283004030977153,
        "rotation": 0,
        "target": "mount-kenya",
        isRoom: true
      },
      {
        "yaw": 1.269275344184416,
        "pitch": 0.003259213091565414,
        "rotation": 0,
        "target": "mombasa",
        isRoom: true
      },
      {
        "yaw": 1.4062713532964817,
        "pitch": 0.000332156094907674,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true
      },
      {
        "yaw": 1.5430039476540105,
        "pitch": 0.010540647720633345,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      },
      {
        "yaw": 1.7634478747400144,
        "pitch": 0.008193865900706143,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      },
      {
        "yaw": 1.661507333199045,
        "pitch": 0.048768824828042199,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      }
    ],
    "infoHotspots": [
      {
        yaw: 4.5,
        pitch: 0, 
        translateX: "-100px",
        translateY: "60px",
        action: "openExternalLink",
        url: "https://www.thi.de/go/energy",
        params: {
          url: "https://www.thi.de/go/energy",
        },
        title: "www.thi.de/go/energy",
        showInNavbar: true,
      }, 
      {
        yaw: 4.68,
        pitch: -.1,
        translateX: "-210px",
        translateY: "300px",
        radius: "720",
        action: "showImageBox",
        thumb: "/material/ines/ines-poster/thumb.jpg",
        params: {
          slides: [
            "/material/ines/ines-poster/x_Seite_1.jpg",
          ],
          type: "quer",
          pdf: "/material/ines/ines-poster/poster.pdf",
        },
        title: "InES poster",
        showInNavbar: true,
       }, {
        yaw: 3.68,
        pitch: -.1,
        translateX: "-150px",
        translateY: "360px",
        type: "quer",
        action: "showImageBox",
        thumb: "/material/ines/flyer/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/ines/flyer/x_Seite_1.jpg",
            "/material/ines/flyer/x_Seite_2.jpg",
          ],
          pdf: "/material/ines/flyer/InES_Flyer.pdf",
          type: "quer",
        },
        title: "InES flyer",
        showInNavbar: true,
       },
       {
        yaw: 4.1,
        pitch: .15,
        translateX: "-735px",
        translateY: "-100px",
        radius: 420,
        action: "showImageBox",
        thumb: "/material/ines/afrika/thumb.jpg",
        params: {
          slides: [
            "/material/ines/afrika/x_Seite_1.jpg"
          ],
          pdf: "/material/ines/afrika/rollup.pdf",
        },
        title: "Network of Academic Research Partners in Africa",
        showInNavbar: true,
       }, {
        yaw: 4.22,
        pitch: -.1,
        radius: 800,
        translateX: "-140px",
        translateY: "150px",
        action: "showImageBox",
        thumb: "/material/ines/emobility/thumb.jpg",
        params: {
          slides: [
            "/material/ines/emobility/x_Seite_1.jpg",
          ],
          type: "quer",
          pdf: "/material/ines/emobility/emobility.pdf",
        },
        title: "e-Mobility Kenya",
        showInNavbar: true,
       },
       {
        yaw: 3.68,
        pitch: -.1,
        translateX: "-400px",
        translateY: "400px",
        radius: 800,
        action: "showImageBox",
        thumb: "/material/ines/oekoflussplan/thumb.jpg",
        params: {
          slides: [
            "/material/ines/oekoflussplan/x_Seite_1.jpg",
          ],
          type:"quer",
          pdf: "/material/ines/oekoflussplan/OekoFlussPlan-Poster.pdf",
        },
        title: "ÖkoFlussPlan",
        showInNavbar: true,
       }
    ]
  },
  {
    "id": "study",
    "parent": "atrium",
    "name": "RE Study Programmes",
    hasChildren: true,
    hasMenu: true,
    "inSubmenu": true,
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 2.283977011239683,
      "pitch": 0.06580456064019558,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": 1.3623835776475488,
        "pitch": 0.023246339338040656,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      },
      {
        "yaw": 1.1994535062510092,
        "pitch": 0.03394483538415649,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      },
      {
        "yaw": -1.2505413820258084,
        "pitch": 0.10879728551655354,
        "rotation": 0,
        "target": "empfang"
      },
     /* {
        "yaw": -0.7457848855391749,
        "pitch": -0.04393081989590186,
        "rotation": 0,
        "target": "biogas-anlage"
      }, */
      {
        "yaw": -1.9925611515391282,
        "pitch": 0.20615660272567027,
        "rotation": 0,
        "target": "ines"
      },
    /*  {
        "yaw": -2.9377470608964806,
        "pitch": -0.020020781646586272,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.9377470608964806,
        "pitch": -0.100020781646586272,
        "rotation": 0,
        "target": "0-lobby"
      }, */
      {
        "yaw": 1.5416930077265043,
        "pitch": 0.0484435752221799,
        "rotation": 0,
        "target": "bayfor",
        isRoom: true
      },
      {
        "yaw": 1.0335129736230897,
        "pitch": 0.03347455002862176,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      },
      {
        "yaw": 0.8588480415811617,
        "pitch": 0.027451489484736413,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true
      },
      {
        "yaw": 0.6754463519013463,
        "pitch": 0.028623944909456966,
        "rotation": 0,
        "target": "mombasa",
        isRoom: true
      },
      {
        "yaw": 0.48695359486499257,
        "pitch": 0.02239394435616049,
        "rotation": 0,
        "target": "mount-kenya",
        isRoom: true
      },
      {
        "yaw": 0.2881301071322806,
        "pitch": 0.014386459997490775,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": 0.07474448482514795,
        "pitch": 0.017817909327963122,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      },
      {
        "yaw": -0.11996190517210614,
        "pitch": 0.02297502623617831,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -0.35196195539213804,
        "pitch": 0.020457133316192255,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      }
    ],
    "infoHotspots": [
      {
        yaw: 2.33,
        pitch: 0,
        translateX: "-100px",
        translateY: "-500px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>RE Study Programmes</h2>",
      },{
        yaw: 2.33,
        pitch: 0,
        translateX: "-590px",
        translateY: "-450px",
        url: 'https://www.thi.de/en/search/employee/prof-dr-ing-sabine-bschorer',
        params: {
          url: 'https://www.thi.de/en/search/employee/prof-dr-ing-sabine-bschorer',
        },

        action: "openExternalLink",
        title: "<b>Academic advisor Bachelor's degree programme: Prof. Dr.-Ing. Sabine Bschorer</small>",
        showInNavbar: true,
      }, {
        yaw: 2.33,
        pitch: 0,
        translateX: "-550px",
        translateY: "-350px",
        url: 'https://www.thi.de/en/search/employee/prof-dr-ing-tobias-schrag-1',
        params: {
          url: 'https://www.thi.de/en/search/employee/prof-dr-ing-tobias-schrag-1',
        },

        action: "openExternalLink",
        title: "Academic advisor Master's degree programme:  Prof. Dr.-Ing. Tobias Schrag",
        showInNavbar: true,
      },  {
        yaw: 2.33,
        pitch: 0,          
        type: "quer",
        translateX: "-620px",
        translateY: "-200px",
        action: "showImageBox",
        thumb: "/material/study/video/thumb.jpg",
        params: {
            video: "/material/study/video/video.mp4",
            type: "quer",
        },
        title: "Video Master's degree programme",
        showInNavbar: true,
      },
     {
      yaw: 2.33,
      pitch: 0, 
      translateX: "-400px",
      translateY: "580px",
      action: "openExternalLink",
      url: "https://www.thi.de/en/mechanical-engineering/degree-programmes/energy-systems-and-renewable-energies-beng",
      params: {
        url: "https://www.thi.de/en/mechanical-engineering/degree-programmes/energy-systems-and-renewable-energies-beng",
      },
      title: "Energy Systems and Renewable Energies (B. Eng.)",
      showInNavbar: true,
    },
      {
        yaw: 2.33, 
        pitch: 0, 
        translateX: "-300px",
        translateY: "700px",
        action: "openExternalLink",
        url: "https://www.thi.de/en/mechanical-engineering/degree-programmes/renewable-energy-systems-msc",
        params: {
          url: "https://www.thi.de/en/mechanical-engineering/degree-programmes/renewable-energy-systems-msc",
        },
        title: "Renewable Energy Systems (M.Sc.)",
        showInNavbar: true,
      },
      {
        yaw: 2.35,
        pitch: 0,
        translateX: "-470px",
        translateY: "180px",
        action: "showImageBox",
        thumb: "/material/study/poster/studiengang_poster.jpg",
        type: "halbquer",
        params: {
          slides: [
            "/material/study/poster/studiengang_poster.jpg",
          ],
          pdf: "/material/study/poster/studiengang_poster.pdf",
          type: "quer"
        },
        title: "Renewable Energy Systems: Overview Study Programmes",
        showInNavbar: true,
       },
       {
        yaw: 2.35,
        pitch: 0,
        translateX: "-100px",
        translateY: "180px",
        action: "showImageBox",
        thumb: "/material/study/flyer_master/x_Seite_1.jpg",
        type: "halbquer",
        params: {
          type: "quer",
          slides: [
            "/material/study/flyer_master/x_Seite_1.jpg",
            "/material/study/flyer_master/x_Seite_2.jpg"
          ],
          pdf: "/material/study/flyer_master/2017_Flyer_Renewable_Energy_Systems_final.pdf",
        },
        title: "Master’s degree programme Renewable Energy Systems",
        showInNavbar: true,
       },{
        yaw: 2.35,
        pitch: 0,
        translateX: "-70px",
        translateY: "-190px",
        action: "showImageBox",
        type: "quer",
        thumb: "/material/study/facts/x.jpg",
        params: {
          slides: [
            "/material/study/facts/x.jpg",
          ],
          pdf: "/material/study/facts/facts.pdf",
          type: "quer"
        },

        title: "Facts & Figures",
        showInNavbar: true,
       },
       
    ]
  },
  {
    "id": "bayfor",
    "parent": "atrium",
    "name": "BayFOR",
    hasChildren: true,
    hasMenu: true,
    "inSubmenu": true,
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 2.1913099867135726,
      "pitch": 0.0694521292313388,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": 2.8995291081616443,
        "pitch": 0.045177785887387856,
        "rotation": 0,
        "target": "study",
        isRoom: true
      },
      {
        "yaw": -2.8414294983069333,
        "pitch": 0.14746016147653052,
        "rotation": 0,
        "target": "ines"
      },
      {
        "yaw": -2.264818771873502,
        "pitch": 0.10258791495312991,
        "rotation": 0,
        "target": "empfang"
      },
   /*   {
        "yaw": -1.861452426615216,
        "pitch": 0.007795300277942729,
        "rotation": 0,
        "target": "biogas-anlage"
      },
      {
        "yaw": 1.430358067178049,
        "pitch": -0.059345181152906434,
        "rotation": 0,
        "target": "waermetechnischer-pruefstand"
      },*/
      {
        "yaw": 0.845110363001286,
        "pitch": 0.062129871929762714,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      },
      {
        "yaw": 0.5028905519695641,
        "pitch": 0.060639535301183045,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      },
      {
        "yaw": 0.24526097599924057,
        "pitch": 0.04467576400730522,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      },
      {
        "yaw": -0.0005823717020394525,
        "pitch": 0.03467346178835484,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true
      },
      {
        "yaw": -0.257665703191968,
        "pitch": 0.03314668683989197,
        "rotation": 0,
        "target": "mombasa",
        isRoom: true
      },
      {
        "yaw": -0.5226052693840426,
        "pitch": 0.01452901179396271,
        "rotation": 0,
        "target": "mount-kenya",
        isRoom: true
      },
      {
        "yaw": -0.7795525487719566,
        "pitch": 0.01617437790194387,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": -1.0123828270379391,
        "pitch": 0.014014716021289786,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      },
      {
        "yaw": -1.225540350075926,
        "pitch": 0.009074540107953055,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -1.4676972345381234,
        "pitch": 0.014436027581904654,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      }
    ],
    "infoHotspots": [
      {
        yaw: 2.15,
        pitch: 0,
        translateX: "20px",
        translateY: "-540px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>BayFOR</h2>",
      },{
        yaw: 2.15,
        pitch: 0,
        translateX: "20px",
        translateY: "-410px",
        url: '#',
        params: {
          url: '#',
        },
        title: "<b>Bayerische Forschungsallianz / Bavarian Research Alliance</b>",
        showInNavbar: true,
      },{
      yaw: 2.15,
      pitch: 0, 
      translateX: "-270px",
      translateY: "750px",
      radius: 1100,
      action: "openExternalLink",
      url: "https://www.bayfor.org/en/",
      params: {
        url: "https://www.bayfor.org/en/",
      },
      title: "More information at <b>www.bayfor.org/en</b>",
      showInNavbar: true,
    },   
    {
      yaw: 2.15,
      pitch: 0,
      translateX: "-110px",
      translateY: "100px",
      action: "showImageBox",
      thumb: "/material/bayfor/rueckblick/x_Seite_01.jpg",
      type: "quadrat",
      params: {
        slides: [
            "/material/bayfor/rueckblick/x_Seite_01.jpg",
            "/material/bayfor/rueckblick/x_Seite_02.jpg",
            "/material/bayfor/rueckblick/x_Seite_03.jpg",
            "/material/bayfor/rueckblick/x_Seite_04.jpg",
            "/material/bayfor/rueckblick/x_Seite_05.jpg",
            "/material/bayfor/rueckblick/x_Seite_06.jpg",
            "/material/bayfor/rueckblick/x_Seite_07.jpg",
            "/material/bayfor/rueckblick/x_Seite_08.jpg",
            "/material/bayfor/rueckblick/x_Seite_09.jpg",
            "/material/bayfor/rueckblick/x_Seite_10.jpg",
            "/material/bayfor/rueckblick/x_Seite_11.jpg",
            "/material/bayfor/rueckblick/x_Seite_12.jpg",
            "/material/bayfor/rueckblick/x_Seite_13.jpg",
            "/material/bayfor/rueckblick/x_Seite_14.jpg",
            "/material/bayfor/rueckblick/x_Seite_15.jpg",
            "/material/bayfor/rueckblick/x_Seite_16.jpg",
            "/material/bayfor/rueckblick/x_Seite_17.jpg",
            "/material/bayfor/rueckblick/x_Seite_18.jpg",
            "/material/bayfor/rueckblick/x_Seite_19.jpg",
            "/material/bayfor/rueckblick/x_Seite_20.jpg",
            "/material/bayfor/rueckblick/x_Seite_21.jpg",
            "/material/bayfor/rueckblick/x_Seite_22.jpg",
            "/material/bayfor/rueckblick/x_Seite_23.jpg",
            "/material/bayfor/rueckblick/x_Seite_24.jpg",
            "/material/bayfor/rueckblick/x_Seite_25.jpg",
            "/material/bayfor/rueckblick/x_Seite_26.jpg",
            "/material/bayfor/rueckblick/x_Seite_27.jpg",
            "/material/bayfor/rueckblick/x_Seite_28.jpg",
            "/material/bayfor/rueckblick/x_Seite_29.jpg",
            "/material/bayfor/rueckblick/x_Seite_30.jpg",
            "/material/bayfor/rueckblick/x_Seite_31.jpg",
            "/material/bayfor/rueckblick/x_Seite_32.jpg",
            "/material/bayfor/rueckblick/x_Seite_33.jpg",
            "/material/bayfor/rueckblick/x_Seite_34.jpg",
            "/material/bayfor/rueckblick/x_Seite_35.jpg",
            "/material/bayfor/rueckblick/x_Seite_36.jpg",
            "/material/bayfor/rueckblick/x_Seite_37.jpg",
            "/material/bayfor/rueckblick/x_Seite_38.jpg",
            "/material/bayfor/rueckblick/x_Seite_39.jpg",
            "/material/bayfor/rueckblick/x_Seite_40.jpg",
            "/material/bayfor/rueckblick/x_Seite_41.jpg",
            "/material/bayfor/rueckblick/x_Seite_42.jpg",
            "/material/bayfor/rueckblick/x_Seite_43.jpg",
            "/material/bayfor/rueckblick/x_Seite_44.jpg",
        ],
        pdf: "/material/bayfor/rueckblick/rueckblick.pdf",
      },
      
      title: "2020 - Zahlen Daten Fakten",
      showInNavbar: true,
     },{
      yaw: 2.15,
      pitch: 0,
      translateX: "-500px",
      translateY: "-250px",
      action: "showImageBox",
      thumb: "/material/bayfor/fullservice/x.jpg",
      type: "quersmall",
      params: {
        slides: [
          "/material/bayfor/fullservice/x.jpg",
        ],
        pdf: "/material/bayfor/fullservice/fullservice.pdf",
        type: "quer",
      },
      title: "BayFOR as Full-Service-Provider",
      showInNavbar: true,
     },{
      yaw: 2.15,
      pitch: 0,
      translateX: "220px",
      translateY: "100px",
      action: "showImageBox",
      thumb: "/material/bayfor/upscale/x.jpg",
      params: {
        slides: [
          "/material/bayfor/upscale/x.jpg",
        ],
        pdf: "/material/bayfor/upscale/poster.pdf",
      },
      title: "Poster Upscale",
      showInNavbar: true,
     },{
      yaw: 2.15,
      pitch: 0,
      translateX: "-500px",
      translateY: "-30px",
      action: "showImageBox",
      thumb: "/material/bayfor/coordination/x.jpg",
      type: "quersmall",
      params: {
        slides: [
          "/material/bayfor/coordination/x.jpg",
        ],
        pdf: "/material/bayfor/coordination/fullservice.pdf",
        type: "quer",
      },
      title: "Scientific Coordination Office (WKS) Bavaria-Africa",
      showInNavbar: true,
     },{
      yaw: 2.15,
      pitch: 0,
      translateX: "-500px",
      translateY: "220px",
      action: "showImageBox",
      thumb: "/material/bayfor/capacity/x.jpg",
      type: "quersmall",
      params: {
        slides: [
          "/material/bayfor/capacity/x.jpg",
        ],
        pdf: "/material/bayfor/capacity/capacitybuilding.pdf",
        type: "quer",
      },
      title: "Capacity Building",
      showInNavbar: true,
     },
     {
      yaw: 2.15,
      pitch: 0,          
      type: "quer",
      translateX: "-110px",
      translateY: "-250px",
      action: "showImageBox",
      thumb: "/material/bayfor/video.jpg",
      params: {
          video: "/material/bayfor/video.mkv",
          type: "quer",
      },
      title: "Video",
      showInNavbar: true,
    },
    ], 
  },
  {
    "id": "proceed",
    "parent": "atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "PROCEED",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.7,
      "pitch": 0.06021389816900012,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": -2.608138302844063,
        "pitch": 0.05123737961849173,
        "rotation": 0,
        "target": "bayfor",
        isRoom: true
      },
      {
        "yaw": -2.2723391256762824,
        "pitch": 0.09154337328335593,
        "rotation": 0,
        "target": "ines"
      },
      {
        "yaw": -1.8606334369339077,
        "pitch": 0.060433930387489454,
        "rotation": 0,
        "target": "empfang"
      },
     /* {
        "yaw": -1.5716286965528035,
        "pitch": 0.003380692099831606,
        "rotation": 0,
        "target": "biogas-anlage"
      },
      {
        "yaw": -2.9016286965528035,
        "pitch": -0.053380692099831606,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.9016286965528035,
        "pitch": -0.123380692099831606,
        "rotation": 0,
        "target": "0-lobby"
      },*/
      {
        "yaw": 2.4340627428081394,
        "pitch": 0.06027631093131447,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      },
      {
        "yaw": 0.5771915738960907,
        "pitch": 0.06295157195180323,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      },
      {
        "yaw": 0.25145672206994973,
        "pitch": 0.03843642350886789,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true
      },
      {
        "yaw": -0.09225118491127304,
        "pitch": 0.04657274047684545,
        "rotation": 0,
        "target": "mombasa",
        isRoom: true
      },
      {
        "yaw": -0.4308786779798126,
        "pitch": 0.03167082527404119,
        "rotation": 0,
        "target": "mount-kenya",
        isRoom: true
      },
      {
        "yaw": -0.6716577744772199,
        "pitch": 0.0266391883905861,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": -0.8632144567645383,
        "pitch": 0.01670710317109858,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      },
      {
        "yaw": -1.0301489895974854,
        "pitch": 0.013075647333320006,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -1.2409259361734897,
        "pitch": 0.0077327077383095855,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      }
    ],
    "infoHotspots": [  
      {
        yaw: 1.6,
        pitch: 0,
        translateX: "-520px",
        translateY: "-300px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>PROCEED</h2>",
      },{
      yaw: 1.6,
      pitch: 0,
      translateX: "-930px",
      translateY: "-180px",
      action: "showImageBox",
      thumb: "/material/proceed/fact_sheet/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/proceed/fact_sheet/x_Seite_1.jpg",
          "/material/proceed/fact_sheet/x_Seite_2.jpg"
        ],
        pdf: "/material/proceed/fact_sheet/fact_sheet.pdf",
      },
      title: "Project Fact Sheet",
      showInNavbar: true,
     },{
        yaw: 1.6,
        pitch: 0,          
        type: "quer",
        translateX: "-630px",
        translateY: "-120px",
        action: "showImageBox",
        thumb: "/material/proceed/video.jpg",
        params: {
            video: "/material/proceed/video.mp4",
            type: "quer",
        },
        title: "Drone video PV System Tsumkwe",
        showInNavbar: true,
      },
     {
      yaw: 1.6,
      pitch: 0,
      translateX: "-860px",
      translateY: "280px",
      action: "showImageBox",
      type: "halbquer",
      thumb: "/material/proceed/expo/x_Seite_01.jpg",
      params: {
        slides: [
          "/material/proceed/expo/x_Seite_01.jpg",
          "/material/proceed/expo/x_Seite_02.jpg",
          "/material/proceed/expo/x_Seite_03.jpg",
          "/material/proceed/expo/x_Seite_04.jpg",
          "/material/proceed/expo/x_Seite_05.jpg",
          "/material/proceed/expo/x_Seite_06.jpg",
          "/material/proceed/expo/x_Seite_07.jpg",
          "/material/proceed/expo/x_Seite_08.jpg",
          "/material/proceed/expo/x_Seite_09.jpg",
          "/material/proceed/expo/x_Seite_10.jpg",
          "/material/proceed/expo/x_Seite_11.jpg",
          "/material/proceed/expo/x_Seite_12.jpg",
          "/material/proceed/expo/x_Seite_13.jpg",
          "/material/proceed/expo/x_Seite_14.jpg",
          "/material/proceed/expo/x_Seite_15.jpg",
        ],
        pdf: "/material/seed/expo/flyer.pdf",
        type: "quer"
      },
      title: "Flyer",
      showInNavbar: true,
     },
     {
      yaw: 1.6,
      pitch: 0,
      translateX: "-500px",
      translateY: "280px",
      type: "halbquer",
      action: "showImageBox",
      thumb: "/material/proceed/poster.jpg",
      params: {
        slides: [
          "/material/proceed/poster.jpg",
        ],
        pdf: "/material/proceed/poster.pdf",
        type: "quer"
      },
      title: "Poster",
      showInNavbar: true,
     },
    ]
  },
  {
    "id": "seed",
    "parent": "atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "SEED",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 2.145631548461295,
      "pitch": 0.1,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": -2.608138302844063,
        "pitch": 0.05123737961849173,
        "rotation": 0,
        "target": "bayfor",
        isRoom: true
      },
      {
        "yaw": -2.2723391256762824,
        "pitch": 0.09154337328335593,
        "rotation": 0,
        "target": "ines"
      },
      {
        "yaw": -1.8606334369339077,
        "pitch": 0.060433930387489454,
        "rotation": 0,
        "target": "empfang"
      },
     /* {
        "yaw": -1.5716286965528035,
        "pitch": 0.003380692099831606,
        "rotation": 0,
        "target": "biogas-anlage"
      },
      {
        "yaw": -2.9016286965528035,
        "pitch": -0.053380692099831606,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.9016286965528035,
        "pitch": -0.123380692099831606,
        "rotation": 0,
        "target": "0-lobby"
      },*/
      {
        "yaw": 1.1971915738960907,
        "pitch": 0.06295157195180323,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      },
      {
        "yaw": 0.5771915738960907,
        "pitch": 0.06295157195180323,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      },
      {
        "yaw": 0.25145672206994973,
        "pitch": 0.03843642350886789,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true
      },
      {
        "yaw": -0.09225118491127304,
        "pitch": 0.04657274047684545,
        "rotation": 0,
        "target": "mombasa",
        isRoom: true
      },
      {
        "yaw": -0.4308786779798126,
        "pitch": 0.03167082527404119,
        "rotation": 0,
        "target": "mount-kenya",
        isRoom: true
      },
      {
        "yaw": -0.6716577744772199,
        "pitch": 0.0266391883905861,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": -0.8632144567645383,
        "pitch": 0.01670710317109858,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      },
      {
        "yaw": -1.0301489895974854,
        "pitch": 0.013075647333320006,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -1.2409259361734897,
        "pitch": 0.0077327077383095855,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      }
    ],
    "infoHotspots": [
      {
        yaw: 2.2,
        pitch: 0,
        translateX: "20px",
        translateY: "-390px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>SEED</h2>",
      },{
        yaw: 2.2,
        pitch: 0, 
        translateX: "-300px",
        translateY: "550px",
        action: "openExternalLink",
        url: "https://www.seed-initiative.org/",
        params: {
          url: "https://www.seed-initiative.org/",
        },
        title: "More information at <b>www.seed-initiative.org</b>",
        showInNavbar: true,
      },{
      yaw: 2.2,
      pitch: 0,
      translateX: "0px",
      translateY: "120px",
      action: "showImageBox",
      type: "halbquer",
      thumb: "/material/seed/expo/x_Seite_01.jpg",
      params: {
        slides: [
          "/material/seed/expo/x_Seite_01.jpg",
          "/material/seed/expo/x_Seite_02.jpg",
          "/material/seed/expo/x_Seite_03.jpg",
          "/material/seed/expo/x_Seite_04.jpg",
          "/material/seed/expo/x_Seite_05.jpg",
          "/material/seed/expo/x_Seite_06.jpg",
          "/material/seed/expo/x_Seite_07.jpg",
          "/material/seed/expo/x_Seite_08.jpg",
          "/material/seed/expo/x_Seite_09.jpg",
          "/material/seed/expo/x_Seite_10.jpg",
          "/material/seed/expo/x_Seite_11.jpg",
        ],
        pdf: "/material/seed/expo/flyer.pdf",
        type: "quer"
      },
      title: "Flyer",
      showInNavbar: true,
     },{
      yaw: 2.2,
      pitch: 0,
      translateX: "-360px",
      translateY: "-80px",
      action: "showImageBox",
      thumb: "/material/seed/fact_sheet/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/seed/fact_sheet/x_Seite_1.jpg",
          "/material/seed/fact_sheet/x_Seite_2.jpg"          
        ],
        pdf: "/material/seed/fact_sheet/fact_sheet.pdf",
      },
      title: "Project Fact Sheet",
      showInNavbar: true,
     },{
      yaw: 2.2,
      pitch: 0,
      translateX: "0px",
      translateY: "-180px",
      action: "showImageBox",
      type: "halbquer",
      thumb: "/material/seed/poster.jpg",
      params: {
        slides: [
          "/material/seed/poster.jpg"
        ],
        type: "quer",
        pdf: "/material/seed/poster.pdf",
      },
      title: "Poster SEED",
      showInNavbar: true,
     }

    ]
  },
  {
    "id": "wip",
    "parent": "atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "WIP",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.03,
      "pitch": 0.05569761155570262,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": 1.8882530266607175,
        "pitch": 0.07918034757379289,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      }, 
      {
        "yaw": 0.20882530266607175,
        "pitch": 0.07918034757379289,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true
      },
      {
        "yaw": 2.6840627428081394,
        "pitch": 0.06027631093131447,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      },
      {
        "yaw": 3.041439029137729,
        "pitch": 0.06417107932332655,
        "rotation": 0,
        "target": "bayfor",
        isRoom: true
      },
      {
        "yaw": -2.9019962089606146,
        "pitch": 0.10183691370796666,
        "rotation": 0,
        "target": "ines"
      },
      {
        "yaw": -2.537236254258449,
        "pitch": 0.08364704195689754,
        "rotation": 0,
        "target": "empfang"
      },
      {
        "yaw": -1.4654446981396276,
        "pitch": 0.03326450113911861,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -1.3090109177490605,
        "pitch": -0.05549184323175325,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -1.2302748976450356,
        "pitch": 0.030242846550063263,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      },
      {
        "yaw": -1.0830227384185939,
        "pitch": -0.02884131045679794,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": -0.9220724481969256,
        "pitch": 0.03914575034594492,
        "rotation": 0,
        "target": "mount-kenya",
        isRoom: true
      },
      {
        "yaw": -0.7283707262462382,
        "pitch": 0.05700226491331151,
        "rotation": 0,
        "target": "mombasa",
        isRoom: true
      },
      /*{
        "yaw": -2.108391240609043,
        "pitch": -0.023417777718400146,
        "rotation": 0,
        "target": "biogas-anlage"
      },
      {
        "yaw": -3.408391240609043,
        "pitch": -0.023417777718400146,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -3.408391240609043,
        "pitch": -0.093417777718400146,
        "rotation": 0,
        "target": "0-lobby"
      }*/
    ],
    "infoHotspots": [
      {
        yaw: 1.08,
        pitch: 0,
        translateX: "-20px",
        translateY: "-400px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>WIP</h2>",
    },
    {
    yaw: 1.04,
    pitch: 0,
    translateX: "-430px",
    translateY: "-200px",
    action: "showImageBox",
        thumb: "/material/wip/video1.jpg",
    type: "halbquersmall",
    params: {
        video: "/material/wip/video1.mp4",
        type: "quer",
    },
    title: "WIP video",
    showInNavbar: true,
   },{
    yaw: 1.04,
    pitch: 0,
    translateX: "-120px",
    translateY: "-200px",
    action: "showImageBox",
    thumb: "/material/wip/video2.jpg",
    type: "halbquersmall",
    params: {
        video: "/material/wip/video2.mp4",
        type: "quer",
    },
    title: "DiBiCoo project video",
    showInNavbar: true,
   },{
    yaw: 1.04,
    pitch: 0,
    translateX: "190px",
    translateY: "-200px",
    action: "showImageBox",
    thumb: "/material/wip/video3.jpg",
    type: "halbquersmall",
    params: {
        video: "/material/wip/video3.mp4",
        type: "quer",
    },
    title: "HYPOSO project video",
    showInNavbar: true,
   },{
    yaw: 1.09,
    pitch: 0,
    translateX: "-340px",
    translateY: "70px",
    action: "showImageBox",
    thumb: "/material/wip/flyer/x_Seite_1.jpg",
    type: "small",
    params: {
      slides: [
        "/material/wip/flyer/x_Seite_1.jpg",
        "/material/wip/flyer/x_Seite_2.jpg",
        "/material/wip/flyer/x_Seite_3.jpg",
      ],
      pdf: "/material/wip/flyer/flyer.pdf",
    },
    title: "Flyer",
    showInNavbar: true,
   },{
    yaw: 1.09,
    pitch: 0,
    translateX: "120px",
    translateY: "70px",
    action: "showImageBox",
    thumb: "/material/wip/energy_transition/x_Seite_1.jpg",
    type: "small",
    params: {
      slides: [
        "/material/wip/energy_transition/x_Seite_1.jpg",
        "/material/wip/energy_transition/x_Seite_2.jpg",
        "/material/wip/energy_transition/x_Seite_3.jpg",
        "/material/wip/energy_transition/x_Seite_4.jpg",
        "/material/wip/energy_transition/x_Seite_5.jpg",
        "/material/wip/energy_transition/x_Seite_6.jpg",
        "/material/wip/energy_transition/x_Seite_7.jpg",
        "/material/wip/energy_transition/x_Seite_8.jpg",
        "/material/wip/energy_transition/x_Seite_9.jpg",
      ],
      pdf: "/material/wip/energy_transition/brochure.pdf",
    },
    title: "Brochure Energy Transition",
    showInNavbar: true,
   },{
    yaw: 1.09,
    pitch: 0,
    translateX: "-112px",
    translateY: "70px",
    action: "showImageBox",
    thumb: "/material/wip/cooperation/x_Seite_01.jpg",
    type: "small",
    params: {
      slides: [
        "/material/wip/cooperation/x_Seite_01.jpg",
        "/material/wip/cooperation/x_Seite_02.jpg",
        "/material/wip/cooperation/x_Seite_03.jpg",
        "/material/wip/cooperation/x_Seite_04.jpg",
        "/material/wip/cooperation/x_Seite_05.jpg",
        "/material/wip/cooperation/x_Seite_06.jpg",
        "/material/wip/cooperation/x_Seite_07.jpg",
        "/material/wip/cooperation/x_Seite_08.jpg",
        "/material/wip/cooperation/x_Seite_09.jpg",
        "/material/wip/cooperation/x_Seite_10.jpg",
      ],
      pdf: "/material/wip/cooperation/brochure.pdf",
    },
    title: "Brochure International Cooperation",
    showInNavbar: true,
   },{
      yaw: 1.09,
      pitch: 0, 
      translateX: "-310px",
      translateY: "480px",
      action: "openExternalLink",
      url: "https://www.wip-munich.de/",
      params: {
        url: "https://www.wip-munich.de/",
      },
      title: "More information at <b>www.wip-munich.de</b>",
      showInNavbar: true,
    }, 
    ]
  },  {
    "id": "tukenya",
    "parent": "atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "Technical University of Kenya",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.11222977981330452,
      "pitch": 0.04569761155570262,
      "fov": 1.4
    },
    "linkHotspots": [
      {
        "yaw": 2.6882530266607175,
        "pitch": 0.07918034757379289,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      },
      {
        "yaw": 2.0882530266607175,
        "pitch": 0.07918034757379289,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      },
      {
        "yaw": 3.041439029137729,
        "pitch": 0.06417107932332655,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      },
      {
        "yaw": 3.651439029137729,
        "pitch": 0.06417107932332655,
        "rotation": 0,
        "target": "bayfor",
        isRoom: true
      },
      {
        "yaw": -2.35,
        "pitch": 0.05183691370796666,
        "rotation": 0,
        "target": "ines"
      },
      {
        "yaw": -1.96,
        "pitch": 0.08364704195689754,
        "rotation": 0,
        "target": "empfang"
      },
      {
        "yaw": -1.44,
        "pitch": 0.03326450113911861,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -1.25,
        "pitch": 0.03549184323175325,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -1.11,
        "pitch": 0.030242846550063263,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      },
      {
        "yaw": -1.0,
        "pitch": 0.02884131045679794,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": -0.7283707262462382,
        "pitch": 0.03914575034594492,
        "rotation": 0,
        "target": "mount-kenya",
        isRoom: true
      },
      {
        "yaw": -0.1,
        "pitch": 0.05700226491331151,
        "rotation": 0,
        "target": "mombasa",
        isRoom: true
      },
     /* {
        "yaw": -1.708391240609043,
        "pitch": -0.023417777718400146,
        "rotation": 0,
        "target": "biogas-anlage"
      },
      {
        "yaw": -2.808391240609043,
        "pitch": -0.023417777718400146,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.808391240609043,
        "pitch": 0.043417777718400146,
        "rotation": 0,
        "target": "0-lobby"
      },
      {
        "yaw": -3.808391240609043,
        "pitch": -0.023417777718400146,
        "rotation": 0,
        "target": "waermetechnischer-pruefstand"
      }*/
    ],
    "infoHotspots": [{
      yaw: 1.14,
      pitch: 0,
      translateX: "-100px",
      translateY: "-400px",
      url: '#',
      isTitle: true,
      params: {
        url: '#',
      },
      title: "<h2>Technical University of Kenya</h2>",
    },{
      yaw: 1.25,
      pitch: 0,
      translateX: "-280px",
      translateY: "-220px",
      action: "showImageBox",
      type: "quer",
      thumb: "/material/tukenya/web.jpg",
      params: {
        slides: [
          "/material/tukenya/web.jpg"
        ],
        pdf: "http://tukenya.ac.ke/",
        pdftitle: "Website",
        type: "quer"
      },
      title: "",
      showInNavbar: true,
     },{
      yaw: 1.14,
      pitch: 0, 
      translateX: "-90px",
      translateY: "80px",
      action: "openExternalLink",
      url: "http://tukenya.ac.ke/",
      params: {
        url: "http://tukenya.ac.ke/",
      },
      title: "More information at <br /><b>tukenya.ac.ke</b>",
      showInNavbar: true,
    },   
      {
        yaw: 1.14,
        pitch: 0,
        translateX: "-550px",
        translateY: "-200px",
        action: "showImageBox",
        type:"halbquer",
        thumb: "/material/tukenya/poster1.jpg",
        params: { 
          slides: [
            "/material/tukenya/poster1.jpg",
          ],
          type:"halbquer",
          pdf: "/material/tukenya/poster1.pdf",
        },
        title: "Poster 1",
        showInNavbar: true,
      },{
        yaw: 1.14,
        pitch: 0,
        translateX: "-550px",
        translateY: "100px",
        action: "showImageBox",
        thumb: "/material/tukenya/poster2.jpg",
        type:"halbquer",
        params: { 
          slides: [
            "/material/tukenya/poster2.jpg",
          ],
          type:"halbquer",
          pdf: "/material/tukenya/poster2.pdf",
        },
        title: "Poster 2",
        showInNavbar: true,
      },  
      {
      yaw: 1.14,
      pitch: 0,
      translateX: "-160px",
      translateY: "280px",
      action: "showImageBox",
      type: "quer",
      thumb: "/material/tukenya/flyer/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/tukenya/flyer/x_Seite_1.jpg",
          "/material/tukenya/flyer/x_Seite_2.jpg",
          "/material/tukenya/flyer/x_Seite_3.jpg",
          "/material/tukenya/flyer/x_Seite_4.jpg",
          "/material/tukenya/flyer/x_Seite_5.jpg"
        ],
        pdf: "/material/tukenya/flyer/flyer.pdf",
        type: "quer"
      },
      title: "Flyer",
      showInNavbar: true,
     }
    ]

    
  },
  {
    "id": "mount-kenya",
    "parent": "atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "Mount Kenya University",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.3083232161371772,
      "pitch": 0.034516214766563635,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": 0.1494559715737065,
        "pitch": 0.05758146441292844,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": -0.04320661976069218,
        "pitch": 0.042656619025414955,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      },
      {
        "yaw": -0.20237960285572143,
        "pitch": 0.039175186850325616,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -0.42142337989298007,
        "pitch": 0.023228635799526387,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -1.0300151470007872,
        "pitch": 0.07096735739732551,
        "rotation": 0,
        "target": "empfang"
      },
      {
        "yaw": -1.3887700027248506,
        "pitch": 0.07982601597943173,
        "rotation": 0,
        "target": "ines"
      },
      {
        "yaw": -1.7116027304709789,
        "pitch": 0.01707664940308895,
        "rotation": 0,
        "target": "study",
        isRoom: true
      },
      {
        "yaw": -1.8653746380674772,
        "pitch": 0.016875233455447614,
        "rotation": 0,
        "target": "bayfor",
        isRoom: true
      },
      {
        "yaw": -2.382708722432671,
        "pitch": 0.027185681214554336,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      },
      {
        "yaw": -2.658417818049621,
        "pitch": 0.05103808427402079,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      },
      {
        "yaw": -3.0186238721994183,
        "pitch": 0.02970499774522395,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      },
      {
        "yaw": 2.7482528054101376,
        "pitch": 0.04803789423183069,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true
      },
     /* {
        "yaw": -0.575988226705892,
        "pitch": -0.061089058997310985,
        "rotation": 0,
        "target": "biogas-anlage"
      },
      {
        "yaw": -1.595988226705892,
        "pitch": -0.061089058997310985,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -1.595988226705892,
        "pitch": 0.02141089058997310985,
        "rotation": 0,
        "target": "0-lobby"
      }*/
    ],
    "infoHotspots": [
      {
        yaw: 1.25,
        pitch: 0,
        translateX: "-530px",
        translateY: "-500px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>Mount Kenya University</h2>",
      },
      {
      yaw: 1.25,
      pitch: 0,
      translateX: "-770px",
      translateY: "-150px",
      action: "showImageBox",
      type: "quer",
      thumb: "/material/mount-kenya/web.jpg",
      params: {
        slides: [
          "/material/mount-kenya/web.jpg"
        ],
        pdf: "https://www.mku.ac.ke/",
        pdftitle: "Website",
        type: "quer"
      },
      title: "",
      showInNavbar: true,
     },
     {
      yaw: 1.15,
      pitch: 0, 
      translateX: "-550px",
      translateY: "160px",
      action: "openExternalLink",
      url: "https://www.mku.ac.ke/",
      params: {
        url: "https://www.mku.ac.ke/",
      },
      title: "More information at <br /><b>www.mku.ac.ke</b>",
      showInNavbar: true,
    },
   



    {
      yaw: 1.35,
      pitch: 0,
      translateX: "900px",
      translateY: "-500px",
      url: '#',
      isTitle: true,
      params: {
        url: '#',
      },
      title: "<h2>Technical University of Mombasa</h2>",
    },

    {
      yaw: 1.35,
      pitch: 0, 
      translateX: "720px",
      translateY: "180px",
      action: "openExternalLink",
      url: "https://www.tum.ac.ke/",
      params: {
        url: "https://www.tum.ac.ke/",
      },
      title: "More information at <br /><b>www.tum.ac.ke</b>",
      showInNavbar: true,
    },
    {
      yaw: 1.3,
      pitch: 0,
      translateX: "730px",
      translateY: "-150px",
      action: "showImageBox",
      thumb: "/material/mombasa/web.jpg",
      type: "quer",
      params: {
        slides: [
          "/material/mombasa/web.jpg",
        ],
        type: "quer",
        pdf: "https://www.tum.ac.ke/",
        pdftitle: "Website"
      },
      title: "",
      showInNavbar: true,
     },     
    ]
  },
  {
    "id": "mombasa",
    "parent": "atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "Technical University of Mombasa",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.33,
      "pitch": 0.05,
      "fov": 1.8
    },
    "linkHotspots": [
      {
        "yaw": 0.1494559715737065,
        "pitch": 0.05758146441292844,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": -0.04320661976069218,
        "pitch": 0.042656619025414955,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      },
      {
        "yaw": -0.20237960285572143,
        "pitch": 0.039175186850325616,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -0.42142337989298007,
        "pitch": 0.023228635799526387,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -1.0300151470007872,
        "pitch": 0.07096735739732551,
        "rotation": 0,
        "target": "empfang"
      },
      {
        "yaw": -1.3887700027248506,
        "pitch": 0.07982601597943173,
        "rotation": 0,
        "target": "ines"
      },
      {
        "yaw": -1.7116027304709789,
        "pitch": 0.01707664940308895,
        "rotation": 0,
        "target": "study",
        isRoom: true
      },
      {
        "yaw": -1.8653746380674772,
        "pitch": 0.016875233455447614,
        "rotation": 0,
        "target": "bayfor",
        isRoom: true
      },
      {
        "yaw": -2.382708722432671,
        "pitch": 0.027185681214554336,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      },
      {
        "yaw": -2.658417818049621,
        "pitch": 0.05103808427402079,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      },
      {
        "yaw": -3.0186238721994183,
        "pitch": 0.02970499774522395,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      },
      {
        "yaw": 2.7482528054101376,
        "pitch": 0.04803789423183069,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true
      },
     /* {
        "yaw": -0.575988226705892,
        "pitch": -0.061089058997310985,
        "rotation": 0,
        "target": "biogas-anlage"
      },
      {
        "yaw": -1.595988226705892,
        "pitch": -0.061089058997310985,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -1.595988226705892,
        "pitch": 0.021089058997310985,
        "rotation": 0,
        "target": "0-lobby"
      } */
    ],
    "infoHotspots": [
      {
        yaw: 1.25,
        pitch: 0,
        translateX: "-530px",
        translateY: "-500px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>Mount Kenya University</h2>",
      },
    {
      yaw: 1.35,
      pitch: 0,
      translateX: "900px",
      translateY: "-500px",
      url: '#',
      isTitle: true,
      params: {
        url: '#',
      },
      title: "<h2>Technical University of Mombasa</h2>",
    }, 
    {
      yaw: 1.35,
      pitch: 0, 
      translateX: "720px",
      translateY: "180px",
      action: "openExternalLink",
      url: "https://www.tum.ac.ke/",
      params: {
        url: "https://www.tum.ac.ke/",
      },
      title: "More information at <br /><b>www.tum.ac.ke</b>",
      showInNavbar: true,
    },
    {
      yaw: 1.3,
      pitch: 0,
      translateX: "730px",
      translateY: "-150px",
      action: "showImageBox",
      thumb: "/material/mombasa/web.jpg",
      type: "quer",
      params: {
        slides: [
          "/material/mombasa/web.jpg",
        ],
        type: "quer",
        pdf: "https://www.tum.ac.ke/",
        pdftitle: "Website"
      },
      title: "",
      showInNavbar: true,
     }, 
      {
        yaw: 1.25,
        pitch: 0,
        translateX: "-530px",
        translateY: "-500px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>Mount Kenya University</h2>",
      },
      {
      yaw: 1.25,
      pitch: 0,
      translateX: "-770px",
      translateY: "-150px",
      action: "showImageBox",
      type: "quer",
      thumb: "/material/mount-kenya/web.jpg",
      params: {
        slides: [
          "/material/mount-kenya/web.jpg"
        ],
        pdf: "https://www.mku.ac.ke/",
        pdftitle: "Website",
        type: "quer"
      },
      title: "",
      showInNavbar: true,
     },
     {
      yaw: 1.15,
      pitch: 0, 
      translateX: "-550px",
      translateY: "160px",
      action: "openExternalLink",
      url: "https://www.mku.ac.ke/",
      params: {
        url: "https://www.mku.ac.ke/",
      },
      title: "More information at <br /><b>www.mku.ac.ke</b>",
      showInNavbar: true,
    },
     
    ]
  },
  {
    "id": "machakos",
    "parent": "atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "Machakos University",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 0.9773843811168277,
      "pitch": 0.07128827402688387,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": -0.021938115524804402,
        "pitch": 0.04026029494173855,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      },
      {
        "yaw": -0.4333071961735797,
        "pitch": 0.037156349926005916,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -0.7959293109313847,
        "pitch": 0.030238437103335514,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      },
      {
        "yaw": -1.5398410022486946,
        "pitch": 0.09339896063618625,
        "rotation": 0,
        "target": "empfang"
      },
     /* {
        "yaw": -0.9741766697693706,
        "pitch": -0.11850575693998167,
        "rotation": 0,
        "target": "biogas-anlage"
      }, */
      {
        "yaw": -1.939968442529107,
        "pitch": 0.10086295980569204,
        "rotation": 0,
        "target": "ines"
      },
     /* {
        "yaw": -2.220997904006584,
        "pitch": -0.0294691441415722,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.220997904006584,
        "pitch": -0.0994691441415722,
        "rotation": 0,
        "target": "0-lobby"
      },*/
      {
        "yaw": -2.3533523838133554,
        "pitch": 0.03444321418630558,
        "rotation": 0,
        "target": "study",
        isRoom: true
      },
      {
        "yaw": -2.544941162568165,
        "pitch": 0.033682856903812564,
        "rotation": 0,
        "target": "bayfor",
        isRoom: true
      },
      {
        "yaw": -3.0144635024097166,
        "pitch": 0.023341751880249006,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      },
      {
        "yaw": 3.0286596711567917,
        "pitch": 0.008821788260016561,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      },
      {
        "yaw": 2.7836293029596195,
        "pitch": 0.03479575618177222,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      },
      {
        "yaw": 2.4852611400630114,
        "pitch": 0.03163264592505577,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true
      },
      {
        "yaw": 2.2225080831782345,
        "pitch": 0.028172038799858967,
        "rotation": 0,
        "target": "mombasa",
        isRoom: true
      },
      {
        "yaw": 1.9711707855608456,
        "pitch": 0.03850744241941939,
        "rotation": 0,
        "target": "mount-kenya",
        isRoom: true
      }
    ],
    infoHotspots: [
      {
        yaw: 1,
        pitch: 0,
        translateX: "0px",
        translateY: "-500px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>Machakos University</h2>",
      },{
      yaw: 1,
      pitch: 0, 
      translateX: "-20px",
      translateY: "390px",
      action: "openExternalLink",
      url: "https://www.mksu.ac.ke/",
      params: {
        url: "https://www.mksu.ac.ke/",
      },
      title: "More information at <br /><b>www.mksu.ac.ke</b>",
      showInNavbar: true,
      },{
        yaw: 1,
        pitch: 0,
        translateX: "-350px",
        translateY: "-200px",
        action: "showImageBox",
        thumb: "/material/machakos/flyer/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/machakos/flyer/x_Seite_1.jpg",
            "/material/machakos/flyer/x_Seite_2.jpg"
          ],
          pdf: "/material/machakos/flyer/flyer.pdf",
        },
        title: "Soaring Heights in Transforming Industry and Economy",
        showInNavbar: true,
       },
        {
        yaw: 1,
        pitch: 0,
        translateX: "0px",
        translateY: "90px",
        action: "showImageBox",
        type: "quadrat",
        thumb: "/material/machakos/website.jpg",
        params: {
          type: "quadrat",
          slides: [
            "/material/machakos/website.jpg"
          ],
          pdf: "https://www.mksu.ac.ke/",
          pdftitle: "Website"
        },
        title: "",
        showInNavbar: true,
       },  
       {
        yaw: 1,
        pitch: 0,          
        type: "quer",
        translateX: "-30px",
        translateY: "-320px",
        action: "showImageBox",
        thumb: "/material/machakos/video/thumb.jpg",
        params: {
            video: "/material/machakos/video/video.mkv",
            type: "quer",
        },
        title: "Video",
        showInNavbar: true,
      },
    ],
  },
  {
    "id": "jomo-kenyatta-university",
    "parent": "atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "Jomo Kenyatta University",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.01,
      "pitch": 0.08,
      "fov": 1
    },
    "linkHotspots": [
      {
        "yaw": -0.1678461408693211,
        "pitch": 0.03956482333043354,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      },
     /* {
        "yaw": -0.5373517204555363,
        "pitch": -0.09726887557530794,
        "rotation": 0,
        "target": "biogas-anlage"
      }, */
      {
        "yaw": -1.2660808927721241,
        "pitch": 0.12159240903912227,
        "rotation": 0,
        "target": "empfang"
      },
      {
        "yaw": -1.7078250022280344,
        "pitch": 0.05604771969652482,
        "rotation": 0,
        "target": "ines"
      },
      {
        "yaw": -2.173226992155943,
        "pitch": 0.008710595249766158,
        "rotation": 0,
        "target": "study",
        isRoom: true
      },
     /* {
        "yaw": -1.9302805836752757,
        "pitch": -0.027092215895601512,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -1.9302805836752757,
        "pitch": -0.097092215895601512,
        "rotation": 0,
        "target": "0-lobby"
      }, */
      {
        "yaw": -2.3825222744218204,
        "pitch": 0.019620747096901,
        "rotation": 0,
        "target": "bayfor",
        isRoom: true
      },
      {
        "yaw": -2.725222744218204,
        "pitch": 0.019620747096901,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      },
      {
        "yaw": 3.369715289841877,
        "pitch": 0.030985467117520926,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      },
      {
        "yaw": 3.1885282753752477,
        "pitch": 0.03663523336487273,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      },
      {
        "yaw": 2.978897718576267,
        "pitch": 0.02446100889060432,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true
      },
      {
        "yaw": 2.82787752865381,
        "pitch": 0.0434823267621951,
        "rotation": 0,
        "target": "mombasa",
        isRoom: true
      },
      {
        "yaw": 2.7346591826804592,
        "pitch": 0.06823301648167757,
        "rotation": 0,
        "target": "mount-kenya",
        isRoom: true
      },
      {
        "yaw": 2.5454809435737715,
        "pitch": 0.08884299467592349,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": 2.0454809435737715,
        "pitch": 0.08884299467592349,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      }
    ],
    "infoHotspots": [
      {
        yaw: 1,
        pitch: 0,
        translateX: "50px",
        translateY: "-500px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>Jomo Kenyatta University</h2>",
      },
      {
      yaw: 1,
      pitch: 0, 
      translateX: "70px",
      translateY: "-300px",
      action: "openExternalLink",
      url: "#",
      params: {
        url: "#",
      },
      title: "Jomo Kenyatta University of Agriculture and Technology",
      showInNavbar: true,
    },{
      yaw: 1,
      pitch: 0,
      translateX: "-220px",
      translateY: "-100px",
      action: "showImageBox",
      type: "quer",
      thumb: "/material/jomo-kenyatta-university/web.jpg",
      params: {
        slides: [
          "/material/jomo-kenyatta-university/web.jpg"
        ],
        pdf: "https://www.jkuat.ac.ke/",
        pdftitle: "Website",
        type: "quer"
      },
      title: "",
      showInNavbar: true,
     },{
      yaw: 1,
      pitch: 0, 
      translateX: "-130px",
      translateY: "200px",
      action: "openExternalLink",
      url: "https://www.jkuat.ac.ke/",
      params: {
        url: "https://www.jkuat.ac.ke/",
      },
      title: "More information at <br /><b>www.jkuat.ac.ke</b>",
      showInNavbar: true,
    }
    ]
  },  {
    "id": "moi-university",
    "parent": "atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "Moi University",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1,
      "pitch": 0.1,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": -0.7278461408693211,
        "pitch": 0.03956482333043354,
        "rotation": 0,
        "target": "kenyatta-university",
        isRoom: true
      },
      /* {
        "yaw": -1.0373517204555363,
        "pitch": -0.09726887557530794,
        "rotation": 0,
        "target": "biogas-anlage"
      },*/
      {
        "yaw": -1.5660808927721241,
        "pitch": 0.12159240903912227,
        "rotation": 0,
        "target": "empfang"
      },
      {
        "yaw": -1.9578250022280344,
        "pitch": 0.10604771969652482,
        "rotation": 0,
        "target": "ines"
      },
      {
        "yaw": -2.423226992155943,
        "pitch": 0.028710595249766158,
        "rotation": 0,
        "target": "study",
        isRoom: true
      },
      /* {
        "yaw": -2.2302805836752757,
        "pitch": -0.027092215895601512,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.2302805836752757,
        "pitch": -0.097092215895601512,
        "rotation": 0,
        "target": "0-lobby"
      },*/
      {
        "yaw": -2.6225222744218204,
        "pitch": 0.039620747096901,
        "rotation": 0,
        "target": "bayfor",
        isRoom: true
      },
      {
        "yaw": -3.0136979629991387,
        "pitch": 0.030498288763514125,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      },
      {
        "yaw": 3.069715289841877,
        "pitch": 0.030985467117520926,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      },
      {
        "yaw": 2.8585282753752477,
        "pitch": 0.03663523336487273,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      },
      {
        "yaw": 2.628897718576267,
        "pitch": 0.02446100889060432,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true
      },
      {
        "yaw": 2.456787752865381,
        "pitch": 0.0434823267621951,
        "rotation": 0,
        "target": "mombasa",
        isRoom: true
      },
      {
        "yaw": 2.346591826804592,
        "pitch": 0.08823301648167757,
        "rotation": 0,
        "target": "mount-kenya",
        isRoom: true
      },
      {
        "yaw": 2.0454809435737715,
        "pitch": 0.08884299467592349,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": -0.1278461408693211,
        "pitch": 0.03956482333043354,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      }
    ],
    "infoHotspots": [ 
      {
        yaw: 1,
        pitch: 0,
        translateX: "50px",
        translateY: "-500px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>Moi University</h2>",
      }, {
        yaw: 1,
        pitch: 0,
        translateX: "-500px",
        translateY: "-320px",
        action: "showImageBox",
        type: "quer",
        thumb: "/material/moi-university/web.jpg",
        params: {
          slides: [
            "/material/moi-university/web.jpg",
          ],
          type: "quer",
          pdf: "https://www.mu.ac.ke/",
          pdftitle: "Website"
        },
        title: "",
        showInNavbar: true,
    }, {
      yaw: 1,
      pitch: 0, 
      translateX: "-420px",
      translateY: "-20px",
      action: "openExternalLink",
      url: "https://www.mu.ac.ke/",
      params: {
        url: "https://www.mu.ac.ke/",
      },
      title: "<small>Moi University website</small>",
      showInNavbar: true,
    },
    {
        yaw: 1,
        pitch: 0,
        translateX: "-500px",
        translateY: "-595px",
        action: "showImageBox",
        type: "halbquer",
        thumb: "/material/moi-university/logo.jpg",
        params: {
          slides: [
            "/material/moi-university/logo.jpg",
          ],
          type: "quer",
          pdf: "https://www.mu.ac.ke/",
          pdftitle: "Website"
        },
        title: "",
        showInNavbar: true,
    }, 
    {
        yaw: 1,
        pitch: 0,          
        type: "quer",
        translateX: "30px",
        translateY: "-320px",
        action: "showImageBox",
        thumb: "/material/moi-university/video/video1thumb.jpg",
        params: {
            video: "/material/moi-university/video/documentary.mp4",
            type: "quer",
        },
        title: "Documentary 2019",
        showInNavbar: true,
      },
    {
      yaw: 1,
      pitch: 0, 
      translateX: "-530px",
      translateY: "120px",
      action: "openExternalLink",
      url: "https://excellencecenter.mu.ac.ke/",
      params: {
        url: "https://excellencecenter.mu.ac.ke/",
      },
      title: "<small>Africa Center of Excellence in Phytochemicals, Textile & Renewable Energy (PTRE)</small>",
      showInNavbar: true,
    },
    {
      yaw: 1,
      pitch: 0, 
      translateX: "-530px",
      translateY: "220px",
      action: "openExternalLink",
      url: "https://ptre-ic.mu.ac.ke/",
      params: {
        url: "https://ptre-ic.mu.ac.ke/",
      },
      title: "<small>Africa Center of Excellence in Phytochemicals, Textile & Renewable Energy IncubationCentre (PTRE-IC)</small>",
      showInNavbar: true,
    },
    {
        yaw: 1,
        pitch: 0,
        translateX: "-450px",
        translateY: "420px",
        action: "showImageBox",
        type: "halbquersmall",
        thumb: "/material/moi-university/miret.jpg",
        params: {
          slides: [
            "/material/moi-university/miret.jpg",
          ],
          type: "quer",
          pdf: "https://miret.mu.ac.ke/",
          pdftitle: "MIRET Website"
        },
        title: "Mobility for Innovative Renewable Energy Technologies (MIRET)",
        showInNavbar: true,
    },
    {
        yaw: 1,
        pitch: 0,
        translateX: "-450px",
        translateY: "420px",
        action: "showImageBox",
        type: "halbquersmall",
        thumb: "/material/moi-university/miret.jpg",
        params: {
          slides: [
            "/material/moi-university/miret.jpg",
          ],
          type: "quer",
          pdf: "https://miret.mu.ac.ke/",
          pdftitle: "MIRET Website"
        },
        title: "Mobility for Innovative Renewable Energy Technologies (MIRET)",
        showInNavbar: true,
    },
    {
        yaw: 1,
        pitch: 0,
        translateX: "-100px",
        translateY: "420px",
        action: "showImageBox",
        type: "halbquersmall",
        thumb: "/material/moi-university/leapre.jpg",
        params: {
          slides: [
            "/material/moi-university/leapre.jpg",
          ],
          type: "quer",
          pdf: "http://www.leap-re.eu/",
          pdftitle: "LEAP-RE Website"
        },
        title: "Long term European African Partnership on Renewable Energy (LEAP-RE)",
        showInNavbar: true,
    },

    {
        yaw: 1,
        pitch: 0,
        translateX: "270px",
        translateY: "420px",
        action: "showImageBox",
        type: "halbquersmall",
        thumb: "/material/moi-university/seea.jpg",
        params: {
          slides: [
            "/material/moi-university/seea.jpg",
          ],
          type: "quer",
          pdf: "https://energy.mu.ac.ke/index.php/en/",
          pdftitle: "SE-EA Website"
        },
        title: "Sustainable Energy & Energy Access (SE-EA)",
        showInNavbar: true,
    },

 
   
    ]
  },
  {
    "id": "kenyatta-university",
    "parent": "atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "Kenyatta University",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 0.9800695030429889,
      "pitch": 0.03838599370677187,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      /* {
        "yaw": -0.012140218994234786,
        "pitch": 0.001892371286086103,
        "rotation": 0,
        "target": "biogas-anlage"
      },*/
      {
        "yaw": -0.7150556640786228,
        "pitch": 0.14093748786234706,
        "rotation": 0,
        "target": "empfang"
      },
      {
        "yaw": -1.1586848623756154,
        "pitch": 0.11006907481636574,
        "rotation": 0,
        "target": "ines"
      },
      /* {
        "yaw": -1.3732730822116075,
        "pitch": -0.0016992466708209975,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -1.3732730822116075,
        "pitch": -0.0816992466708209975,
        "rotation": 0,
        "target": "0-lobby"
      },*/
      {
        "yaw": -1.6715472827345899,
        "pitch": 0.021286537461854493,
        "rotation": 0,
        "target": "study",
        isRoom: true
      },
      {
        "yaw": -1.8816066332182046,
        "pitch": 0.021242268054539437,
        "rotation": 0,
        "target": "bayfor",
        isRoom: true
      },
      {
        "yaw": -2.2071971788050746,
        "pitch": 0.021945132669914713,
        "rotation": 0,
        "target": "seed",
        isRoom: true
      },
      {
        "yaw": -2.377084399868348,
        "pitch": 0.02672588043862234,
        "rotation": 0,
        "target": "proceed",
        isRoom: true
      },
      /* {
        "yaw": -2.067329459374667,
        "pitch": -0.048041464028313996,
        "rotation": 0,
        "target": "waermetechnischer-pruefstand"
      }, */
      {
        "yaw": -2.5522923620638203,
        "pitch": 0.02971533368138779,
        "rotation": 0,
        "target": "wip",
        isRoom: true
      },
      {
        "yaw": -2.731837280243001,
        "pitch": 0.0264091056419975,
        "rotation": 0,
        "target": "tukenya",
        isRoom: true
      },
      {
        "yaw": -2.8717623562427015,
        "pitch": 0.0171685913751314,
        "rotation": 0,
        "target": "mombasa",
        isRoom: true
      },
      {
        "yaw": -2.9647631948460447,
        "pitch": 0.06312552856393026,
        "rotation": 0,
        "target": "mount-kenya",
        isRoom: true
      },
      {
        "yaw": -3.121515691527007,
        "pitch": 0.019450556926837095,
        "rotation": 0,
        "target": "machakos",
        isRoom: true
      },
      {
        "yaw": 2.8492967071652817,
        "pitch": 0.06631282597668786,
        "rotation": 0,
        "target": "moi-university",
        isRoom: true
      },
      {
        "yaw": 2.3748050637531914,
        "pitch": 0.10138371917419953,
        "rotation": 0,
        "target": "jomo-kenyatta-university",
        isRoom: true
      }
    ],
    "infoHotspots": [
   /* {
      yaw: 1,
      pitch: 0,
      translateX: "50px",
      translateY: "-370px",
      url: '#',
      params: {
        url: '#',
      },
      title: "<b>Mit Blended Learning zu energieeffizienten Wärmenetzen im ländlichen Raum</b>",
      showInNavbar: true,
    },*/{
      yaw: 1,
      pitch: 0,
      translateX: "50px",
      translateY: "-550px",
      url: '#',
      isTitle: true,
      params: {
        url: '#',
      },
      title: "<h2>Kenyatta University</h2>",
    },{
      yaw: 1,
      pitch: 0,
      translateX: "-150px",
      translateY: "300px",
      radius: 1100,
      action: "openExternalLink",
      url: "http://www.ku.ac.ke/",
      params: {
        url: "http://www.ku.ac.ke",
      },
      title: "More information at <br /><b>www.ku.ac.ke</b>",
      showInNavbar: true,
    }, {
      yaw: 1,
      pitch: 0,
      translateX: "-220px",
      translateY: "-100px",
      action: "showImageBox",
      type: "quer",
      thumb: "/material/kenyatta-university/web.jpg",
      params: {
        slides: [
          "/material/kenyatta-university/web.jpg"
        ],
        pdf: "http://www.ku.ac.ke",
        pdftitle: "Website",
        type: "quer"
      },
      title: "",
      showInNavbar: true,
     }, /*{
      yaw: 1,
      pitch: 0,
      translateX: "-30px",
      translateY: "-70px",
      action: "showImageBox",
      type: "quer",
      thumb: "/material/effnet/projektvorstellung/x_Seite_01.jpg",
      params: {
        slides: [
          "/material/effnet/projektvorstellung/x_Seite_01.jpg",
          "/material/effnet/projektvorstellung/x_Seite_02.jpg",
          "/material/effnet/projektvorstellung/x_Seite_03.jpg",
          "/material/effnet/projektvorstellung/x_Seite_04.jpg",
          "/material/effnet/projektvorstellung/x_Seite_05.jpg",
          "/material/effnet/projektvorstellung/x_Seite_06.jpg",
          "/material/effnet/projektvorstellung/x_Seite_07.jpg",
          "/material/effnet/projektvorstellung/x_Seite_08.jpg",
          "/material/effnet/projektvorstellung/x_Seite_09.jpg",
          "/material/effnet/projektvorstellung/x_Seite_10.jpg",
          "/material/effnet/projektvorstellung/x_Seite_11.jpg"
        ],
        pdf: "/material/effnet/projektvorstellung/projektvorstellung.pdf",
        type: "quer",
      },
      title: "Projektvorstellung",
      showInNavbar: true,
     }*/
    ]
  },
];
export default ATRIUM; 
