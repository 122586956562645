import React from "react";
import styled from "../../../theming/styled";

const HotspotImage = ({ children, thumb = "", translateX = "0px", translateY = "0px", type = "slideshow"}) => {
  return (
    <HotspotHolder className={`label-center ${type}`} /*style={{ transform: `translate(${translateX}, ${translateY})`}}*/>
      <HotspotFlag style={{ backgroundImage: `url(${thumb})` }} />
      <span>{children}</span>
    </HotspotHolder>
  );
};
export default HotspotImage;

const HotspotFlag = styled.div(() => ({
  width: "100%",
  height: "100%",
  background: "none 50%/cover no-repeat transparent",
}));

const HotspotHolder = styled.div(() => ({
  height: "353px",
  minWidth: "250px",
  backgroundColor: "#fff",
  cursor: "pointer",
  display: "block",
  color: "#fff",
  fontFamily: "News Cycle",
  fontSize: "1.8em",
  transition: "all 0.3s",
  borderRadius: "1px",
  boxShadow: "15px 15px 47px -20px rgba(0,0,0,0.75)",

  "&.quer": {
    minWidth: "500px",
    height: "281px",
    fontSize: "1.8em"
  },
  "&.scopesmall": {
    minWidth: "680px",
    height: "220px",
    fontSize: "1.5em"
  },
  "&.halbquer": {
    minWidth: "326px",
    height: "230px",
    fontSize: "1.8em"
  },
 
  "&.quadrat": {
    minWidth: "280px",
    height: "265px"
  },

  "&.small": {
    minWidth: "186px",
    height: "258px",
    fontSize: "1.5em"
  },
  "&.halbsmall": {
    minWidth: "230px",
    height: "328px",
    fontSize: "1.5em"
  },
  "&.quersmall": {
    minWidth: "300px",
    height: "150px",
    fontSize: "1.5em"
  },
  "&.halbquersmall": {
    minWidth: "262px",
    height: "160px",
    fontSize: "1.5em"
  },
  "&.lang": {
    minWidth: "1030px",
    height: "136px",
    pointerEvents: "none"
  },
  "&.live": {
    minWidth: "200px",
    height: "78px",
    fontSize: "1.5em"
  },
  "&:hover > div:after": {
    display: "none"
  },
  "&:hover": {
    boxShadow: "2px 3px 22px 4px rgba(181,201,235,1)"
  },
  "span": {
    display: "block",
    textAlign: "center"
  }
}));
