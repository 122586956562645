import React from "react";
import styled from "../../../theming/styled";

import { defaultTheme } from "./../../../theming/theme";
const { colors, fonts } = defaultTheme; // cause not in context

const Hotspot = ({ children, labelDirection = "center", room = ""}) => {
  return (
    <HotspotHolder className={`pulse-border label-${labelDirection}`}>
      <HotspotFlag className={`${room}`}>
        <span>{children}</span>
      </HotspotFlag>
    </HotspotHolder>
  );
};
export default Hotspot;

const HotspotFlag = styled.div(() => ({
  maxWidth: "3rem", 
  width: "3rem",
  transition: " .25s all",
  boxSizing: "border-box",
  overflow: "hidden",
  whiteSpace: "nowrap",
  fontFamily: fonts.fontFamily,
  fontSize: fonts.fontSizeHeading[0].fontSizeHeading4,
  color: colors.buttonActiveText,
  padding: ".5rem 1rem",
  cursor: "pointer", 
  background: "#029ccf",
//  boxShadow: "0 0 23px 5px #38a3ff",
  borderRadius: "0",
  height: "3rem",
  margin: "auto",
  lineHeight: "1.2em",

  "&.room": {
    background: "#025a9f",
    maxWidth: "420px",
    width: "auto",
    minWidth: "3rem",
    "&:hover": {
      background: "#029ccf"      
    },
    "> span": {
      opacity: "1",

    "&:hover": {
      textShadow: "0 0 10px white"
    }
    },
    "&:after": {
      animation: "none",
      border: "0"
    }
  },
  span: {
    opacity: 0,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    left: "-3px",
    top: "-3px",
    width: "calc(3rem - 14px)",
    height: "calc(3rem - 14px)",
    border: "10px solid rgb(255, 255, 255, .7)", 
    borderRadius: "0",
    animation: "pulse-border 1.5s ease-out infinite",
    pointerEvents: "none"
  },
}));

const HotspotHolder = styled.div(() => ({
  height: "3rem",
  minWidth: "3rem",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",

  "&:hover > div:after": {
    display: "none"
  },

  "&.label-right": {
    position: "absolute",
    left: '-1.25rem',
    top: '-1.25rem',
  },
  "&.label-left": {
    position: "absolute",
    right: '-1.25rem',
    top: '-1.25rem',
  },
  "&:hover": {
    div: {
      maxWidth: "420px",
      width: "auto",
      minWidth: "3rem",
      span: {
        opacity: 1,
      },
    },
  }
}));
