import React from "react";
import styled from "../../../theming/styled";

import { defaultTheme } from "./../../../theming/theme";
const { fonts } = defaultTheme; // cause not in context


const HotspotButton = ({ children, url = "", type = "" }) => {
  return (
    <div>

    {(url !== "#") && (
        <HotspotTextHolder href={url} target="_blank" className={`label-center ${type}`}>
        <span dangerouslySetInnerHTML={{ __html:children}}></span>
        </HotspotTextHolder>
    )}
    {(url === "#" && children !== "" ) && (
        <HotspotTextHolder className={`label-center ${type}`}>
        <span dangerouslySetInnerHTML={{ __html:children}}></span>
        </HotspotTextHolder>
      )}

    </div>
  );
};
export default HotspotButton;

const HotspotTextHolder = styled.a(() => ({
    fontFamily:fonts.fontFamily,
    fontSize:'2rem',
    whiteSpace:'nowrap',
    color: 'white',
    padding:'20px 40px',
    backgroundColor: "rgb(93,140,186)",
    textDecoration: "none",
    cursor:'pointer',
    pointerEvents: "all",
    textShadow: "15px 15px 47px -20px rgba(0,0,0,0.75)",
    display: "block",
    '&:hover':{
      textShadow: '#FFF 0px 0px 2px, #FFF 0px 0px 5px, #FFF 0px 0px 8px'
    },
    '&.small': {
      fontSize: '1.5rem',
      padding: '10px 20px'
    }
  }));

