import React from "react";
import styled from "../../theming/styled";

const Lobby = ({room}) => {

  const LobbyContainer = styled.div(() => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "url(/assets/lobby.jpg) 50% 80%/cover no-repeat black",
    display: "none",

    "&.lobby": {
        display: "block"
    },
    
    ".flag-container": {
        height: "100%",
        display: "flex",
        justifyContent: "center",


        ".flag": {
            background: "rgba(0, 90, 155, .85)",
            width: "400px",
            padding: "20px",
            color: "white",
            fontFamily: "News Cycle",
            fontSize: "1em",
            lineHeight: "1.4em",  
            margin: "auto 50px",
            
            "&.agenda img": {
                maxWidth: "300px",
                boxShadow: "15px 15px 47px -20px rgba(0,0,0,0.75)",

                "&:hover": {
                    boxShadow: "2px 3px 22px 4px rgba(181,201,235,1)"
                }
            },
            
            "h2": {
                fontWeight: "normal",
                marginTop: "10px"
            },

            "a.button": {
                background: "#5d8cba",
                color: "#ffffff",
                padding: "5px 20px 8px",
                textDecoration: "none",
                margin: "10px 0",
                display: "inline-block",

                "&:hover": {
                    background: "#fff",
                    color: "rgba(0, 90, 155, 1)"
                }
            }
        }
    }
  }));


  return (
    <LobbyContainer className={room === "0-lobby" ? "lobby" : ""}>
        <div className="flag-container">
            <div className="flag airmeet">
                <h2>Lobby</h2>
                <p>Unsere Lobby bietet Ihnen die Möglichkeit sich digital mit unseren Ansprechpartnern oder anderen Teilnehmern auszutauschen.</p>
                <p>Die Sprechzeiten unserer Ansprechpartner finden Sie in der Übersicht.</p>
                <a className="button" href="https://www.airmeet.com/e/fb5f52c0-3a38-11eb-9884-3d7a1eb5c063" target="_blank" rel="noopener noreferrer" >Lobby betreten</a>    
            </div>

            <div className="flag agenda">
                <h2>Hinweise zur Lobby</h2>
                <a href="material/lobby/lobby-anleitung.pdf" target="_blank">
                    <img src="material/lobby/lobby-anleitung.jpg" alt="Anleitung" />   
                </a>
                <h2>Sprechzeiten</h2>
                <a href="material/lobby/lobby-sprechzeiten.pdf" target="_blank">
                    <img src="material/lobby/lobby-sprechzeiten.jpg" alt="Sprechzeiten" />   
                </a>
            </div>
        </div>
    </LobbyContainer>
     
  );
};

export default Lobby;
