import React, { useState } from "react";
import { useActionContext } from "./../../utils";
import Pano from "./../../components/pano/Pano"; 
import ContentElements from "./../../components/content/contentElements";
import Navbar from "./../../components/nav/Navbar";
import LectureHall from "./../../components/lecturehall/LectureHall";
import Lobby from "./../../components/lecturehall/Lobby";

const Content = () => {
  const [room, setRoom] = useState("");
  const { dispatch: dispatchAction } = useActionContext();

  return (
    <>      
      <Pano
        onRoomChange={(room) => setRoom(room)}
        onActionEvent={(type, params) =>
          dispatchAction({ type, params })
        }
      />

      <Lobby room={room} />
      <LectureHall room={room} />
      
      <ContentElements />
      <Navbar room={room} />
    </>
  );
};

export default Content;