const WTP = [
  {
    "id": "waermetechnischer-pruefstand",
    "parent": "atrium",
    "name": "Thermal Test Bench",
    hasChildren: true,
    hideFromMenu: true,
    hasMenu: false,
    initialYawLimiter: {
      min: 0,
      max: 1.9,
    },
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.4634982395671585,
      "pitch": 0.09541695050207721,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      /*{
        "yaw": -.9,
        "pitch": 0.05858839311794739,
        "rotation": 0,
        "target": "atrium",
        isRoom: true
      },*/
    ],
    "infoHotspots": [
      {
        yaw: 1.2,
        pitch: 0,
        translateX: "-200px",
        translateY: "-650px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Heating circle 1",
        showInNavbar: true,
       }, {
        yaw: 1.2,
        pitch: 0,
        translateX: "140px",
        translateY: "-635px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Heating circle 2",
        showInNavbar: true,
       },{
        yaw: 1.8,
        pitch: 0,
        translateX: "140px",
        translateY: "-600px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Tap water",
        showInNavbar: true,
       },{
        yaw: 2.1,
        pitch: 0,
        translateX: "140px",
        translateY: "-620px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Cooling circle 1",
        showInNavbar: true,
       },{
        yaw: 2.4,
        pitch: 0,
        translateX: "140px",
        translateY: "-700px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Cooling circle 2",
        showInNavbar: true,
       },{
        yaw: 1.2,
        pitch: 0,
        translateX: "100px",
        translateY: "0px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Test object 1",
        showInNavbar: true,
       },{
        yaw: 1.8,
        pitch: 0,
        translateX: "550px",
        translateY: "200px",
        action: "openExternalLink",
        url: "#",
        params: {
          url: "#",
        },
        title: "Test object 2",
        showInNavbar: true,
       },/*{
        yaw: .1,
        pitch: -.1,
        translateX: "220px",
        translateY: "-270px",
        radius: 1000,
        type: "halbquer",
        action: "showImageBox",
        thumb: "/material/wtp/kurzbeschreibung.jpg",
        params: {
          slides: [
            "/material/wtp/kurzbeschreibung.jpg",
          ],
          type: "halbquer",
        },
        title: "Überblick",
        showInNavbar: true,
       },*/
       {
        yaw: 0,
        pitch: 0,
        translateX: "-720px",
        translateY: "480px",
        type: "small",
        radius: "900",
        action: "openExternalLink",
        url: '#',
        params: {
          url: '#',
        },
        title: "<b>contact persons:</b><br />Prof. Dr.-Ing. Tobias Schrag (tobias.schrag@thi.de)<br />Jürgen Schwarzer (juergen.Schwarzer@thi.de)",
        showInNavbar: true,
      },
       {
        yaw: 0,
        pitch: 0,
        translateX: "-920px",
        translateY: "-20px",
        radius: 1000,
        type: "halbquersmall",
        action: "showImageBox",
        thumb: "/material/wtp/uebersicht.jpg",
        params: {
          slides: [
            "/material/wtp/uebersicht.jpg",
          ],
          type: "quer",
        },
        title: "Range of performance",
        showInNavbar: true,
       },{
        yaw: 0,
        pitch: 0,
        translateX: "-920px",
        translateY: "200px",
        radius: 1000,
        type: "halbquersmall",
        action: "showImageBox",
        thumb: "/material/wtp/pruefling.jpg",
        params: {
          slides: [
            "/material/wtp/pruefling.jpg",
          ],
          type: "quer",
        },
        title: "Connection of test object",
        showInNavbar: true,
       },{
        yaw: 0,
        pitch: 0,
        translateX: "-620px",
        translateY: "-20px",
        radius: 1000,
        type: "halbquersmall",
        action: "showImageBox",
        thumb: "/material/wtp/hydraulik.jpg",
        params: {
          slides: [
            "/material/wtp/hydraulik.jpg",
          ],
          type: "quer",
        },
        title: "Measuring points hydraulic",
        showInNavbar: true,
       },{
        yaw: 0,
        pitch: 0,
        translateX: "-620px",
        translateY: "200px",
        radius: 1000,
        type: "halbquersmall",
        action: "showImageBox",
        thumb: "/material/wtp/umgebung.jpg",
        params: {
          slides: [
            "/material/wtp/umgebung.jpg",
          ],
          type: "quer",
        },
        title: "Measuring Environment",
        showInNavbar: true,
       },{
        yaw: 0,
        pitch: 0,
        translateX: "-550px",
        translateY: "-320px",
        radius: 600,
        type: "halbquer",
        action: "showImageBox",
        thumb: "/material/wtp/einleitung.jpg",
        params: {
          slides: [
            "/material/wtp/einleitung.jpg",
          ],
          type: "quer",
        },
        title: "Thermal Test Bench",
        showInNavbar: true,
       },
    ]
  },
  
];
export default WTP;
