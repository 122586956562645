import React from "react";
import styled from "../../theming/styled";
import { useActionContext } from "./../../utils";
import "slick-carousel/slick/slick.css";
const TextBox = ({ text }) => {
const { dispatch: dispatchAction } = useActionContext();

  return (
<TextBoxContainer onClick={() =>
        dispatchAction({
          type: "closeContent",
        })
      }>
      <TextBoxDim>
          <div dangerouslySetInnerHTML={{__html: text}}></div></TextBoxDim>      
    </TextBoxContainer>
  );
};

export default TextBox;

const TextBoxContainer = styled.div(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(0,0,0,0.5)",
}));
const TextBoxDim = styled.div(({ theme: { colors, fonts } }) => ({
  cursor: "pointer",
  background: "rgba(255,255,255,0.9)",
  color: "#015a9f",
  top: 0,
  left: 0,
  maxWidth: "800px",
  boxSizing: "border-box",
  padding: "20px 40px 40px",
  fontFamily: fonts.fontFamily,
  fontSize: "1rem",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
    "a": {
        color: "#ffffff",
        background: "#015a9f",
        textDecoration: "none",
        padding: "10px 20px",
        display: "inline-block",
        margin: "30px 0",

        "&:hover": {
          background: "rgb(93,140,186)"
        },
        "&:after": {
          transition: "all 0.3s ease 0s",
          content: "",
          position: "absolute",
          top: 0,
          bottom: 0,
          right: "-30px",
          width: "250px",
          background: "rgba(255,255,255,0.3)",
          transform: "skewX(-200deg)",
          zIndex: 1
        },
        "&:hover:after": {
            width: "80%"
        }
    }
}));

