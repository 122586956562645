import React from 'react';
import './Navbar.scss';
import NavOptions from './NavOptions';

const Submenu = (props) => {
    let submenuOptions = props.navOptions.map(({name, id}) => ({
        label: name,
        action: "sceneLink",
        sceneId: id,
        })
    );

    return (
        <nav className="submenu">
            <NavOptions navOptions={submenuOptions} position="nav-vertical" onUpdateImageBox={props.onUpdateImageBox}  />   
        </nav>
    );
}

export default Submenu;