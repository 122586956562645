import React from "react";
import "./App.css";
import { ThemeProvider } from "emotion-theming";
import { defaultTheme } from "./theming/theme";
import { HologramContextProvider } from "./utils/hologramContext";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { TransitionContextProvider, ActionContextProvider } from "./utils";
import Content from "./components/content/content";

function App() {


  return (
    <ThemeProvider theme={defaultTheme}>
      <TransitionContextProvider>
        <HologramContextProvider>
          <ActionContextProvider>
            <Router>
              <Switch>
                <Route path="/:room?">
                  <Content/>
                </Route>
              </Switch>
            </Router>
          </ActionContextProvider>
        </HologramContextProvider>
      </TransitionContextProvider>
    </ThemeProvider>
  );
}

export default App;
