import React from "react";
import Hotspot from "./Hotspot";
import HotspotText from "./HotspotText";
import HotspotButton from "./HotspotButton";
import HotspotImage from "./HotspotImage";
import HotspotInvisibleText from "./HotspotInvisibleText";
import VideoHotspot from "./VideoHotspot";
import SlideshowHotspot from "./SlideshowHotspot";
import BubbleHotspot from "./BubbleHotspot";

let isDragging = false;
let mouseDownX, mouseDownY;
const dragTolerance = 9;

const onMouseDown = (e) => {
  isDragging = false;
  mouseDownX = e.screenX;
  mouseDownY = e.screenY;
};
const onMouseMove = (e) => {
  if (Math.abs(mouseDownX - e.screenX) > dragTolerance || Math.abs(mouseDownY - e.screenY) > dragTolerance) {
    isDragging = true;
  }
};
const onMouseUp = (e, action) => {
  if (isDragging) {
    isDragging = false;
    return;
  }
  action();
};

const parseHotspots = (input, clickEvent, clickEventInfo) => {
  const [
    { linkHotspots = [], embeddedHotspots = [], infoHotspots = [] } = {},
  ] = input.filter(({ current }) => current === true);

  const linkHotspotsWidthName = linkHotspots.map((hs) => {
    const [{ name = "" } = {}] = input.filter(({ id }) => id === hs.target);
    return { ...hs, label: name };
  });

  const clickable = ({
    onClick,
    label,
    bubble = null,
    transform,
    asText = false,
    isRoom=false,
    isTitle=false,
    asInvisibleText = false,
    labelDirection = "center",
    clickAreaSize = "large",
    thumb = "",
    translateX = "0px",
    translateY = "0px",
    type = "slideshow",
    url = "",
  }) => (
    <div className={isRoom ? "room" : isTitle ? "title" : (url === '#') ? "notClickable" : "clickable"} style={{ transform: `translate(${translateX}, ${translateY})`}} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={(e) => {onMouseUp(e, onClick)}} data-transform={transform}>
      {asText && <HotspotText>{label}</HotspotText>}
      {asInvisibleText && <HotspotInvisibleText clickAreaSize={clickAreaSize}>{label}</HotspotInvisibleText>}
      {thumb !== "" && (
        <HotspotImage thumb={thumb} translateX={translateX} translateY={translateY} type={type}>
          {label}
        </HotspotImage>
      )}
      {url !== "" && (
        <HotspotButton url={url} type={type}>
          {label}
        </HotspotButton>
      )}
      {!asText && !asInvisibleText && thumb === "" && url === "" && (
        <Hotspot labelDirection={labelDirection} room={isRoom ? "room" : ""}>
          {bubble ? "..." : label}
        </Hotspot>
      )}
    </div>
  );

  const video = (id, source, transform, translation, isHologram) => (
    <div
      data-transform={transform}
      className={isHologram ? "hologram" : ""}
      data-hologram-type="video"
      data-id={id}
      style={isHologram ? { display: "none" } : {}}
    >
      <VideoHotspot source={source} translation={translation}></VideoHotspot>
    </div>
  );

  const slideshow = (slides, id, transform, translation, isHologram) => (
    <div
      data-transform={transform}
      className={isHologram ? "hologram" : ""}
      data-hologram-type="slideshow"
      data-id={id}
      style={isHologram ? { display: "none" } : {}}
    >
      <SlideshowHotspot
        slides={slides}
        translation={translation}
      ></SlideshowHotspot>
    </div>
  );

  const speechBubble = (bubble, id, transform, translation, direction) => (
    <div
      data-transform={transform}
      className="hologram"
      data-hologram-type="bubble"
      data-id={id}
      style={{ display: "none" }}
    >
      <BubbleHotspot
        quote={bubble.quote}
        person={bubble.person}
        translation={translation}
        direction={direction}
      ></BubbleHotspot>
    </div>
  );

  const embeddedHotspotTypes = ({
    pitch,
    yaw,
    radius,
    translateX,
    translateY,
    type,
    slides,
    id,
    source,
    bubble = {},
    direction = "right",
    isHologram = false
  }) =>
    ({
      video: video(
        id,
        source,
        {
          coords: { yaw, pitch, radius },
          rotation: { x: 0, y: 0, z: 0 },
        },
        { x: translateX, y: translateY },
        isHologram
      ),
      slideshow: slideshow(
        slides,
        id,
        {
          coords: { yaw, pitch, radius },
          rotation: { x: 0, y: 0, z: 0 },
        },
        { x: translateX, y: translateY },
        isHologram
      ),
      bubble: speechBubble(
        bubble,
        id,
        {
          coords: { yaw, pitch, radius },
          rotation: { x: 0, y: 0, z: 0 },
        },
        { x: translateX, y: translateY },
        direction
      ),
    }[type]);

  const hotspots = [];

  embeddedHotspots.map((params) => hotspots.push(embeddedHotspotTypes(params)));

  linkHotspotsWidthName.map(
    ({
      pitch,
      yaw,
      target,
      label,
      bubble = null,
      asText = false,
      isRoom = false,
      isTitle = false,
      asInvisibleText = false,
      radius = 1000,
      labelDirection = "center",
      clickAreaSize = "large"
    }) =>
      hotspots.push(
        clickable({
          onClick: () => clickEvent(target),
          label,
          bubble,
          transform: {
            coords: { yaw, pitch, radius },
            rotation: { x: 0, y: 0, z: 0 },
          },
          asText,
          isRoom,
          isTitle,
          asInvisibleText,
          labelDirection,
          clickAreaSize
        })
      )
  );

  infoHotspots.map(
    ({
      pitch,
      yaw,
      title = "",
      radius = 1000,
      action,
      params,
      labelDirection = "center",
      clickAreaSize = "large",
      thumb = "",
      translateX = "0px",
      translateY = "0px",
      type = "slideshow",
      url = "",
      isTitle = false
    }) =>
      hotspots.push(
        clickable({
          onClick: () => clickEventInfo(action, params),
          label: title,
          transform: {
            coords: { yaw, pitch, radius },
            rotation: { x: 0, y: 0, z: 0 },
          },
          labelDirection,
          clickAreaSize,
          thumb,
          translateX, 
          translateY,
          type,
          url,
          isTitle
        })
      )
  );

  return hotspots;
};
export { parseHotspots };