
const parseScenes = (input) => {
  const result = input.map((scene) => {
    return {
      ...scene,
      type: "cubemap",
      imageUrl: ({ face, z, y, x }) => {
        const prefix = `assets/tiles/${scene.id}`;
        if (z === 0) {
          const mapY = "lfrbud".indexOf(face) / 6;
          return {
            url: `${prefix}/preview.jpg`,
            rect: { x, mapY, width: 1, height: 1 / 6 },
          };
        } 
        return {
          url: `${prefix}/${z}/${face}/${y}/${x}.jpg`,
        };
      },
    };
  });


  return result;
};
export { parseScenes };
