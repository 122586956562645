import React from "react";
import styled from "../../theming/styled";

const LectureHall = ({room}) => {

  const LectureHallContainer = styled.div(() => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "url(/assets/vorlesungsraum.jpg) 50% 80%/cover no-repeat black",
    display: "none",

    "&.lecture-hall": {
        display: "block"
    },

    ".flag-container": {
        height: "100%",
        display: "flex",
        justifyContent: "center",

        ".flag": {
            background: "rgba(0, 90, 155, .85)",
            width: "400px",
            padding: "20px",
            color: "white",
            fontFamily: "News Cycle",
            fontSize: "1em",
            lineHeight: "1.4em",  
            margin: "auto 50px",
            
            "&.agenda img": {
                maxWidth: "300px",
                boxShadow: "15px 15px 47px -20px rgba(0,0,0,0.75)",

                "&:hover": {
                    boxShadow: "2px 3px 22px 4px rgba(181,201,235,1)"
                }
            },
            
            "h2": {
                fontWeight: "normal",
                marginTop: "10px"
            },

            "a.button": {
                background: "#5d8cba",
                color: "#ffffff",
                padding: "5px 20px 8px",
                textDecoration: "none",
                margin: "10px 0",
                display: "inline-block",

                "&:hover": {
                    background: "#fff",
                    color: "rgba(0, 90, 155, 1)"
                }
            }
        }
    }
  }));

  return (
    <LectureHallContainer className={room === "0-lecture-hall" ? "lecture-hall" : ""}>
        <div className="flag-container">
            <div className="flag zoom">
                <h2>Vortrags-Livestream</h2>
                <p>Unsere Vorträge sind mittels der Software Zoom zugänglich. Über folgende Links gelangen Sie direkt zum Live-Stream:</p>
                
                <a className="button" href="https://thi-de.zoom.us/j/97575939379?pwd=dkdDV0U3VjVydjFWYVFQN0FRWnNZZz09" target="_blank" rel="noopener noreferrer" >Zoom-Meeting beitreten</a> 
                
                <p>Meeting-ID: 975 7593 9379<br />Kenncode: 968775</p>
                <p>Über diesen Link ist auch eine Teilnahme ohne Zoom-Client möglich (Nach Öffnen des Links „Mit Ihrem Browser anmelden“ auswählen). Wir empfehlen jedoch eine Teilnahme über den Zoom Client, welchen Sie direkt über den Link herunterladen können (siehe auch Zoom-Anleitung).</p> 

                <p>Sollten Sie Zoom nicht installieren können, ist es auch möglich über Skype for Business / Teams teilzunehmen:</p>             
                <a className="button" href="https://thi-de.zoom.us/skype/97575939379" target="_blank" rel="noopener noreferrer" >Über Skype for Business beitreten</a>    

            </div>
            <div className="flag agenda">
                <h2>Agenda runterladen</h2>
                <a href="material/empfang/agenda/BG40_Agenda_Fachtagung_16.12.2020_FINAL.pdf" target="_blank">
                    <img src="material/lecturehall/agenda.jpg" alt="Agenda" />   
                </a>
                <h2>Zoom-Anleitung</h2>
                <a href="material/lecturehall/Zoom_FAQ.pdf" target="_blank">
                    <img src="material/lecturehall/zoom.png" alt="Zoom" />   
                </a>
            </div>
        </div>
    </LectureHallContainer>
     
  );
};

export default LectureHall;
