import React from "react";
import styled from "../../../theming/styled";

import { defaultTheme } from "./../../../theming/theme";
const { fonts } = defaultTheme; // cause not in context

const HotspotInvisibleText = ({ children, clickAreaSize="large"}) => {
  return (
    <HotspotInvisibleTextHolder className={clickAreaSize}>
     <span>{children}</span>
    </HotspotInvisibleTextHolder>
  );
};
export default HotspotInvisibleText;

const HotspotInvisibleTextHolder = styled.div(() => ({
  fontFamily:fonts.fontFamily,
  fontSize:'2rem',
  whiteSpace:'nowrap',
  color: 'white',
  marginTop:'15rem',
  backgroundColor:'transparent',
  cursor:'pointer',

  span: {
    opacity: 0,
  },
  "&.large": {
    padding:'0 11rem 15rem',
  },
  "&.middle": {
    padding:'0 1rem 15rem',
  },
  "&.small": {
    padding:'0 0rem 15rem',
    fontSize:'1rem'
  }
}));

