const CC_BLUE = 'rgb(34, 112, 184)'
const CC_BLUE_DARKER = 'rgb(32,112,150)'
const WHITE = '#fff'
const LIGHT_GREY = '#E7E7E7'
const LIGTHER_GREY = '#f8f8f8'
const INNER_SHADOW = 'rgba(0,0,0,0.05)'
const TEXT = '#20292E'
const RED = '#FF2626'
const G1 = '#003f7d'
const G2 = '#ced72d'
const G3 = '#54bdbf'
const G4 = '#0c7fa7'
const G6 = '#82c074'

const DEFAULT_GRADIENT = `linear-gradient(90deg, ${G1} 0%, ${G4} 27%, ${G3} 50%, ${G6} 73%, ${G2} 100%)`

export const defaultTheme = {
  colors: {
    border: LIGHT_GREY,
    odd: LIGHT_GREY,
    category19: LIGHT_GREY,
    category20: LIGHT_GREY,
    category21: LIGHT_GREY,
    category22: LIGHT_GREY,
    categoryNoTime: LIGHT_GREY,
    highlight: CC_BLUE_DARKER,
    primary: CC_BLUE,
    secondary: WHITE,
    tertiary: WHITE,
    text: TEXT,
    heading: TEXT,
    dim: "rgba(0,0,0,0.8)",
    buttonText: WHITE,
    buttonActiveText: LIGHT_GREY,
    buttonBackground: LIGHT_GREY,
    buttonActiveBackground: TEXT,
    buttonSecondaryBackground: LIGHT_GREY,
    buttonSecondaryActiveBackground: TEXT,
    link: CC_BLUE,
    linkHover: CC_BLUE,
    lightestBackground: WHITE,
    lightBackground: LIGTHER_GREY,
    innerShadow: INNER_SHADOW,
    error: RED,
    attention: RED,
    defaultGradient: DEFAULT_GRADIENT,
  },
  dimensions: {
    maxWidth: '1280px',
  },
  fonts: {
    fontFamily: 'News Cycle',
    iconFont: '',
    fontSize: ['1rem', '1.15rem', '1.25rem'],
    fontSizeSmall: ['0.5rem', '0.66rem', '0.75rem'],
    fontSizeForms: '1.25rem',
    fontSizeHeading: [
      {
        fontSizeHeading1: '2.5rem',
        fontSizeHeading2: '2rem',
        fontSizeHeading3: '1.75rem',
        fontSizeHeading4: '1.5rem',
        fontSizeHeading5: '1.25rem',
      },
      {
        fontSizeHeading1: '2.5rem',
        fontSizeHeading2: '2rem',
        fontSizeHeading3: '1.75rem',
        fontSizeHeading4: '1.5rem',
        fontSizeHeading5: '1.25rem',
      },
      {
        fontSizeHeading1: '2.5rem',
        fontSizeHeading2: '2rem',
        fontSizeHeading3: '1.75rem',
        fontSizeHeading4: '1.5rem',
        fontSizeHeading5: '1.25rem',
      },
    ],
  },
}

export type Theme = typeof defaultTheme
