import { useEffect, useState } from "react";
import marzipano from "marzipano";

const viewerOpts = {
  controls: {
    mouseViewMode: "drag",
  }
};

const useViewer = (container) => {

  const [viewer, setViewer] = useState<any>(null);
  useEffect(() => {
    if (container === null) {
      throw TypeError("Container is empty");
    }
    if (container && container.current && viewer === null) {
      const view = new marzipano.Viewer(container.current, viewerOpts);
      
      setViewer(view);
    }
    return () => {
      if (viewer !== null) {
        viewer.destroy();
        setViewer(null);
      }
    };
  }, [container, viewer]);
  

  return viewer;
};

export default useViewer;
