import React from "react";
import { createContext } from "use-context-selector";

export const defaultState = {
  yaw: 0,
  pitch: 0,
  fov: 1.4376949633870055, // from atrium -> fix to dynamic
};

export type State = typeof defaultState;

export interface Action {
  type: "UPDATE_TRANSITION";
  state: { yaw: number; pitch: number; fov: number };
}

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "UPDATE_TRANSITION":
      return {
        ...state,
        yaw: action.state.yaw,
        pitch: action.state.pitch,
        fov: action.state.fov,
      };

    default:
      return state;
  }
};

export const TransitionContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({ state: defaultState, dispatch: () => null });

export const TransitionContextProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  return (
    <TransitionContext.Provider value={{ state, dispatch }}>
      {props.children}
    </TransitionContext.Provider>
  );
};

export const useTransitionContext = () => React.useContext(TransitionContext);
