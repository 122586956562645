const NAV_DATA = {
  firstLevelSubmenu: {
    label: "Movable walls",
  },
  navbarLeft: [
/*    {
      key: "home",
      label: "",
      icon: "home-256",
      action: "sceneLink",
      sceneId: "atrium",
    },*/
    {
      key: "back",
      label: "Back to lobby",
      icon: "arrow-back-256",
      action: "goBack",
    },
  ],
  navbarRight: [
    {
      key: "lecture",
      label: "Enter main stage",
      /*action: "sceneLink",
      sceneId: "0-lecture-hall",*/
      action: "openLink",
      sceneId: "https://meet.teamgeist.com/v/uas_kenya_linking_science_and_industry/live/mainstage"
    },
  ],
};

export default NAV_DATA;
