import React from "react";
import styled from "../../theming/styled";
import { useActionContext } from "./../../utils";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";

const ImageBox = ({ image, slides, pdf, pdftitle, video, type }) => {
  const { dispatch: dispatchAction } = useActionContext();

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
    infinite:false
  };

  return (
<ImageBoxContainer id="imagebox-container"
    >
      <ImageBoxDim onClick={() =>
        dispatchAction({
          type: "closeContent",
        })
      }></ImageBoxDim>
      {image && <ImageBoxContent className={type} src={image} />}
      {pdf && pdftitle && <PDFDownloadButton href={pdf} target="_blank">{pdftitle}</PDFDownloadButton>}
      {pdf && !pdftitle && <PDFDownloadButton href={pdf} target="_blank">Download PDF</PDFDownloadButton>}
      {video &&  (
          <VideoHotspotHolder>
              <Plyr
                source={{
                  type: "video",
                  sources: [{
                      src: video,
                  }],
                }}
                options={{
                    resetOnEnd: true,
                }}
              />
          </VideoHotspotHolder>
      )}
      {slides && (
        <SideshowHotspotHolder  className={type}>
          <Slider {...settings}>
            {slides &&
              slides.map((slide, index) => {
                return (
                  <div key="$(index)" className="slide xxx">
                    <img src={slide} alt="Slide" />
                  </div>
                );
              })}
          </Slider>
        </SideshowHotspotHolder>
      )}
    </ImageBoxContainer>
  );
};

export default ImageBox;

const VideoHotspotHolder = styled.div(() => ({
  backgroundColor: "transparent",
  position: "absolute",
    width: "80%",
    height: "80%",
    background: "transparent"
  
}));

const ImageBoxContainer = styled.div(({ theme: { colors, fonts } }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
 
}));
const ImageBoxDim = styled.div(({ theme: { colors, fonts } }) => ({
  cursor: "pointer",
  background: colors.dim,
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
}))

const PDFDownloadButton = styled.a(({ theme: { colors, fonts } }) => ({
  cursor: "pointer",
  position: "absolute",
  textDecoration: "none",
  background: "#005a9b",
  color: "#fff",
  top: "20px",
  right: "20px",
  fontFamily: "News Cycle",
  padding: "10px 30px"
  
}))

const ImageBoxContent = styled.img(({ theme: { colors, fonts } }) => ({
  display: "block",
  margin: "auto",
}));

const SideshowHotspotHolder = styled.div(() => ({
  width: "70vh",
  "&.quer": {
    width: "70vw"
  },
  position: "absolute",
  '.slick-slider':{
    img:{
      width:'100%',
    }
  },
 ".slide": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "10rem",
    textAlign: "center",

    ".slideImage": {
      backgroundSize: "contain",
      backgroundPosition: "center center",
    },
  },
  ".slick-arrow": {
    width: "30px",
    height: "30px",
    fontSize: 0,
    padding: 0,
    border: "none",
    outline: "none",
    background: "transparent",
    color: "#fff",
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    "&.slick-prev": {
      left: "-3rem",
      transform: "rotate(180deg)",
    },
    "&.slick-next": {
      right: "-3rem",
      marginTop:'-50px'

    },
    "&:after": {
      content: "'>'",
      fontSize: "3rem",
      fontFamily: 'News Cycle',
      fontWeight: 300
    }, 
    "&.slick-disabled": {
      opacity:0
    }
  },
}));
