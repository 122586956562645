import React, { useState, useEffect } from "react";
import { useActionContext } from "./../../utils";

import styled from "../../theming/styled";
import ImageBox from "../nav/ImageBox";
import TextBox from "../nav/TextBox";
const ContentElements = () => {
  const [content, setContent] = useState(<></>);
  const { state: actionState } = useActionContext();
  const { type: actionType, params: actionParameters } = actionState;

  useEffect(() => {
    const { image = "", text="", slides = null, pdf="", pdftitle="", video="", type="hoch", url = "" } = actionParameters || {};
    
    switch (actionType) {
      case "showImageBox":
        setContent(<ImageBox slides={slides} image={image} pdf={pdf} pdftitle={pdftitle} video={video} type={type} />);
        return;
      case "openExternalLink":
        console.log("url", {url});
        if (url !== "#") {
          window.open(`${url}`);
        }
        return;
      case "showTextBox":
        setContent(<TextBox text={text} />);
        return;
      case "closeContent":
        setContent(<></>);
        break;
      default:
        return;
    }
  }, [actionState, actionType, actionParameters]);

  return <ContentElementsOuter className="contentElement">{content}</ContentElementsOuter>;
};

export default ContentElements;

const ContentElementsOuter = styled.div(({ theme: { colors, fonts } }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  backfaceVisibility:'hidden'
}));